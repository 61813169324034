import styled from 'styled-components';

export const H2PageStyled = styled.div`
  .h2_p_title {
    text-align: center;
    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
    color: ${({ theme }) => theme.COLORS.WHITE_2};
  }

  .theme_standard {
  }
`;
