import { SpinWheelWidgetStyled } from './styled';

const SpinWheelWidget = () => {
  return (
    <SpinWheelWidgetStyled>
      <img src="/assets/images/mockup/wheel-game.png" alt="wheel" />
    </SpinWheelWidgetStyled>
  );
};

export default SpinWheelWidget;
