import styled from 'styled-components';

export const PolicyStyled = styled.div`
  position: relative;
  border-radius: 16px;
  width: 100%;
  max-width: 350px;
  background: ${({ theme }) => theme.COLORS.WHITE_2};
  .close {
    position: absolute;
    top: 10px;
    right: 14px;
    cursor: pointer;
  }
  .p_body_scoll {
    padding: 18px 12px;
    max-height: calc(67vh - 36px);
    overflow: auto;
    ::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
    ::-webkit-scrollbar-button {
      width: 0px;
      height: 0px;
    }
    ::-webkit-scrollbar-thumb {
      background: #e1e1e1;
      border: 0px none #ffffff;
      border-radius: 50px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #ffffff;
    }
    ::-webkit-scrollbar-thumb:active {
      background: #6b6b6b;
    }
    ::-webkit-scrollbar-track {
      background: #666666;
      border: 0px none #ffffff;
      border-radius: 50px;
    }
    ::-webkit-scrollbar-track:hover {
      background: #666666;
    }
    ::-webkit-scrollbar-track:active {
      background: #b0b0b0;
    }
    ::-webkit-scrollbar-corner {
      background: transparent;
    }
    .body_content {
      height: 100%;
      .pdpa_title {
        margin-bottom: 8px;
        // text-align: center;
        font-size: ${({ theme }) => theme.FONT.SIZE.S20};
        font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
        color: ${({ theme }) => theme.COLORS.BLACK_2};
      }
      .h_title {
        margin-bottom: 8px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S18};
        font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
        color: ${({ theme }) => theme.COLORS.BLACK_2};
      }
      .sub_title {
        margin-bottom: 8px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
        color: ${({ theme }) => theme.COLORS.BLACK_2};
      }
      .pdpa_content {
        margin-bottom: 8px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
        color: ${({ theme }) => theme.COLORS.GRAY_5};
        &.red {
          font-size: ${({ theme }) => theme.FONT.SIZE.S14};
          font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_BOLD};
          color: ${({ theme }) => theme.COLORS.RED_1};
          &.no_mb {
            margin-bottom: 0px;
          }
        }
      }
      .pdpa_cancel {
        margin-bottom: 12px;
        color: ${({ theme }) => theme.COLORS.RED_1};
      }
      .pdpa_input_wrap {
        margin-bottom: 20px;
      }
      .pdpa_action_row {
        display: flex;
        column-gap: 16px;
        .pdpa_action_col {
          flex: 1;
        }
      }
    }
  }
  .pdpa_cancel {
    padding: 4px 12px 0px 12px;
    color: ${({ theme }) => theme.COLORS.RED_1};
  }
  .pdpa_action_col {
    padding: 10px 12px;
  }
`;
