import styled from 'styled-components';

export const MessageContainerStyled = styled.div`
  .loading_show {
    height: calc(100vh - 199px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .reward_container {
    border-radius: 4px;
    padding: 16px 14px;
    background: ${({ theme }) => theme.COLORS.WHITE_2};
    .rc_title {
      margin-bottom: 16px;
      text-align: center;
      font-size: ${({ theme }) => theme.FONT.SIZE.S20};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
      color: ${({ theme }) => theme.COLORS.BLACK_2};
    }
    .rc_list {
      .no_data_txt {
        text-align: center;
        padding: 100px 0;
        font-size: ${({ theme }) => theme.FONT.SIZE.S12};
        font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_LIGHT};
        color: ${({ theme }) => theme.COLORS.GRAY_5};
      }
      .item_wrap {
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 10px;
        }
      }
    }
  }
`;
