import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { CheckboxStyled } from './styled';

const Checkbox = ({
  theme_standard,
  theme_gray,
  placeholder,
  name,
  value,
  errors,
  onClick,
  ...props
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
    theme_gray: theme_gray,
  });

  return (
    <CheckboxStyled>
      <div className={customClass}>
        <div className="input_checkbox_container" onClick={() => onClick && onClick(value)}>
          <input id={name} type="checkbox" checked={value} {...props} />
          {placeholder && (
            <label htmlFor={name} className="placeholder">
              {placeholder}
            </label>
          )}
        </div>
        {errors && <div className="errors">{errors}</div>}
      </div>
    </CheckboxStyled>
  );
};

Checkbox.propTypes = {};

export default Checkbox;
