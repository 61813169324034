import React from 'react';
import { LoginContainerStyled } from './styled';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { userService } from 'apiServices';
import { toast } from 'react-toastify';
import { ROUTE_PATH } from 'utils/constants/routePath';
import { withRouter } from 'navigations/withRouter';
import { setReduxAuthen } from 'store/actions';
import { Forms } from 'components';

class LoginContainer extends React.Component {
  handleClickChangLang = () => {
    const { i18n } = this.props;
    i18n.changeLanguage('en');
  };

  handleSubmit = async (values) => {
    const { t } = this.props;
    let params = {
      ...values,
    };
    let res = await userService.POST_LOGIN(params);
    if (res && res.status === 200) {
      localStorage.setItem('token', res.token);
      let resProfile = await userService.GET_MY_PROFILE();
      if (resProfile && resProfile.status === 200) {
        this.props.setReduxAuthen(resProfile.data);
        toast.success(t('Log in success', { ns: 'translation' }));
        this.props.router.navigate(ROUTE_PATH.PRODUCT_SCAN_QR_CODE);
      }
    } else {
      toast.error(t('Login failed', { ns: 'translation' }));
    }
  };

  render() {
    return (
      <LoginContainerStyled>
        <Forms.LoginForm onSubmit={this.handleSubmit} />
      </LoginContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxAuthen: (data) => dispatch(setReduxAuthen(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('profile')(withRouter(LoginContainer)));
