import styled from 'styled-components';

export const ProfileFormStyled = styled.div`
  padding: 14px 0 0 0;
  .avatar_wrap {
    margin-bottom: 18px;
    text-align: center;
  }
  .input_col_wrap {
    margin-bottom: 16px;
    &.mb_8 {
      margin-bottom: 8px;
    }
    &.mb_24 {
      margin-bottom: 24px;
    }
    &.pd_24 {
      padding-left: 20px;
    }
    .read_field {
      color: ${({ theme }) => theme.COLORS.WHITE_1};
    }
    .highlight {
      cursor: pointer;
      margin-left: 5px;
      color: ${({ theme }) => theme.COLORS.RED_1};
    }
  }
`;
