export default function renderIcon({ color = '#FF0000' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24.406"
      height="24.4"
      viewBox="0 0 24.406 24.4"
    >
      <path
        id="Path_686"
        data-name="Path 686"
        d="M436.772,16.179h6.583v8.806h-6.583Zm8.866-.019h6.56v8.824h-6.56Zm0-5.526h8.81V13.89h-8.81Zm-11.122-.01h8.811v3.254h-8.811Zm4.827-2.271a1.642,1.642,0,0,1-1.532-1.711,1.617,1.617,0,0,1,1.659-1.579,3.8,3.8,0,0,1,1.729.62,5.416,5.416,0,0,1,1.785,2.681c-.77,0-1.5.012-2.22.012-.478,0-.95-.006-1.421-.024m9.505-3.233a1.7,1.7,0,0,1,1.881.5A1.535,1.535,0,0,1,451,7.381a1.671,1.671,0,0,1-1.664,1c-1.082-.007-2.163,0-3.376,0,.658-1.464,1.285-2.793,2.891-3.258M436.813,3.93a3.77,3.77,0,0,0-.947,4.131,1.963,1.963,0,0,1,.037.319c-.831,0-1.618-.012-2.4,0-.746.014-1.189.389-1.2,1.1q-.039,2.78,0,5.561a1.059,1.059,0,0,0,1.1,1.083c.333.022.669,0,1.1,0v.708q0,4.5,0,8.993c0,1,.386,1.392,1.4,1.393q8.578,0,17.157,0c1.046,0,1.41-.38,1.41-1.443q0-4.5,0-8.992v-.656h.734c1.127,0,1.477-.356,1.479-1.495q0-2.426,0-4.851c0-1.029-.374-1.4-1.379-1.41-.742,0-1.484,0-2.273,0,.085-.311.149-.535.206-.762a3.844,3.844,0,0,0-3.319-4.742,4.788,4.788,0,0,0-4.171,1.663c-.472.511-.856,1.1-1.294,1.675-.1-.156-.188-.307-.282-.455a5.868,5.868,0,0,0-3.129-2.664,5.184,5.184,0,0,0-1.576-.266,3.689,3.689,0,0,0-2.652,1.1"
        transform="translate(-432.281 -2.828)"
        fill={color}
      />
    </svg>
  );
}
