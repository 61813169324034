export default function renderIcon({ color = '#FF0000' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25.788"
      height="25.994"
      viewBox="0 0 25.788 25.994"
    >
      <g
        id="Group_177"
        data-name="Group 177"
        transform="translate(-46.023 -813.399)"
      >
        <path
          id="Path_692"
          data-name="Path 692"
          d="M29.679,6.434a3.127,3.127,0,0,1-3.2,1.673C23.639,8,20.793,8.066,17.95,8.076a2.814,2.814,0,0,0-3.1,3.077q-.015,6.955,0,13.91A2.8,2.8,0,0,0,17.894,28.1q6.955.026,13.909,0a2.781,2.781,0,0,0,3.046-3.028c.023-3.113.1-6.231-.033-9.339-.059-1.425.7-2.114,1.759-2.995v.953c0,3.791.009,7.581,0,11.371a4.474,4.474,0,0,1-4.7,4.736q-7.006.018-14.011,0a4.439,4.439,0,0,1-4.706-4.709q-.022-7.006,0-14.011a4.414,4.414,0,0,1,4.713-4.689c3.621-.012,7.243,0,10.864,0,.29,0,.579.028.944.047"
          transform="translate(32.876 809.587)"
          fill={color}
        />
        <path
          id="Path_693"
          data-name="Path 693"
          d="M39.989,20.068,36.9,16.937,47.356,6.523l3.061,3.125L39.989,20.068"
          transform="translate(18.687 809.503)"
          fill={color}
        />
        <path
          id="Path_694"
          data-name="Path 694"
          d="M67.849,5.176,64.3,1.568,66.717,0c.91.865,2.124,1.58,2.65,2.637.586,1.178-1.055,1.567-1.518,2.539"
          transform="translate(2.319 813.399)"
          fill={color}
        />
        <path
          id="Path_695"
          data-name="Path 695"
          d="M33.448,34.343l3.017,3.1L32.272,38.62l1.176-4.277"
          transform="translate(21.452 792.884)"
          fill={color}
        />
      </g>
    </svg>
  );
}
