import React, { useEffect, useState } from 'react';
import { ProfileFormStyled } from './styled';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import address from 'utils/jsons/thailandAddress/address.json';
import * as yup from 'yup';
import { RENDER_STORE_TYPE } from 'utils/functions/store';
import {
  // AcceptPDPA,
  Policy,
} from 'containers/RegisterSystem/RegisterContainer/Steps/RegisterStoreInfomationContainer/Modals';
import { Inputs, Buttons, Modals } from 'components';

const ProfileForm = ({ onSubmit, initialValues }) => {
  const { t } = useTranslation(['form', 'common']);

  const schema = yup.object().shape({
    firstname: yup
      .string()
      .required(t('Please enter firstname', { ns: 'form' })),
    lastname: yup.string().required(t('Please enter lastname', { ns: 'form' })),
    phone_number: yup
      .string()
      .required(t('Please enter phone number', { ns: 'form' })),
    address: yup.string().required(t('Please enter address', { ns: 'form' })),
    province: yup
      .object()
      .required(t('Please select province', { ns: 'form' }))
      .nullable(),
    district: yup
      .object()
      .required(t('Please select district', { ns: 'form' }))
      .nullable(),
    sub_district: yup
      .object()
      .required(t('Please select sub district', { ns: 'form' }))
      .nullable(),
    zipcode: yup.string().required(t('Please enter zipcode', { ns: 'form' })),
  });

  const {
    control,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [_isShowModal, _setIsShowModal] = useState();
  const [_renderModal, _setRenderModal] = useState();
  const [_provinceOption, _setProvinceOption] = useState([]);
  const [_districtOption, _setDistrictOption] = useState([]);
  const [_subdistrictOption, _setSubdistrictOption] = useState([]);

  useEffect(() => {
    _setProvinceOption(
      address.reduce((result, data, i) => {
        if (i === 0) {
          result.push({ label: data.province, value: data.province });
        } else {
          let find = result.find((e) => e.value === data.province);
          if (!find) {
            result.push({ label: data.province, value: data.province });
          }
        }
        return result;
      }, [])
    );
  }, []);

  const _handleChangeProvince = (e) => {
    setValue('district', '');
    setValue('sub_district', '');
    setValue('zipcode', '');
    _setDistrictOption(
      address.reduce((result, data, i) => {
        if (data.province === e.value) {
          // let find = result.find((e) => e.value === data.amphoe);
          // if (!find) {
          result.push({
            label: `${data.amphoe} ${data.district} ${data.zipcode}`,
            value: data.amphoe,
          });
          // }
        }
        return result;
      }, [])
    );
  };

  const _handleChangeDistrict = (e) => {
    let splitData = e.label.split(' ');
    setValue('sub_district', { label: splitData[1], value: splitData[1] });
    setValue('zipcode', splitData[2]);
    _setSubdistrictOption(
      address.reduce((result, data, i) => {
        if (data.amphoe === e.value) {
          let find = result.find((e) => e.value === data.district);
          if (!find) {
            result.push({
              label: data.district,
              value: data.district,
              zipcode: data.zipcode,
            });
          }
        }
        return result;
      }, [])
    );
  };

  const _handleChangeSubDistrict = (e) => {
    let find = _subdistrictOption.find((f) => f.value === e.value);
    if (find) {
      setValue('zipcode', find.zipcode);
    }
  };

  useEffect(() => {
    if (initialValues) {
      reset({
        ...initialValues,
        province: initialValues.province
          ? { label: initialValues.province, value: initialValues.province }
          : [],
        district: initialValues.district
          ? { label: initialValues.district, value: initialValues.district }
          : [],
        sub_district: initialValues.sub_district
          ? {
              label: initialValues.sub_district,
              value: initialValues.sub_district,
            }
          : [],
        img_path: initialValues.img_path
          ? [{ media_uri: initialValues.img_path }]
          : [],
      });
    }
  }, [initialValues]); // eslint-disable-line react-hooks/exhaustive-deps

  const _handleClickEnbPAPD = (key) => {
    switch (key) {
      case 'accept_profile_info':
        _setIsShowModal(true);
        _setRenderModal(<Policy isUnAccept handleClose={_handleCloseModal} />);
        break;
      case 'accept_pdpa':
        _setIsShowModal(true);
        _setRenderModal(<Policy isUnAccept handleClose={_handleCloseModal} />);
        break;
      default:
        break;
    }
  };

  const _handleCloseModal = () => {
    _setIsShowModal(false);
  };

  return (
    <ProfileFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="avatar_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.AvatarUpload
                {...field}
                // errors={errors.phone?.message}
              />
            )}
            name="img_path"
            defaultValue={''}
          />
        </div>
        <div className="input_col_wrap">
          <div className="read_field">
            ประเภทร้านค้า :{' '}
            {t(RENDER_STORE_TYPE(initialValues.status), { ns: 'form' })}
          </div>
        </div>
        <div className="input_col_wrap">
          <div className="read_field">
            สาขา : {initialValues.is_main_branch ? 'สาขาหลัก' : 'สาขาย่อย'}
          </div>
        </div>
        <div className="input_col_wrap">
          <div className="read_field">
            รหัสร้านค้า : {initialValues.shop_no}
          </div>
        </div>
        <div className="input_col_wrap"></div>
        <div className="input_col_wrap"></div>
        <div className="input_col_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                placeholder={t('Name', { ns: 'form' })}
                errors={errors.firstname?.message}
              />
            )}
            name="firstname"
            defaultValue=""
          />
        </div>
        <div className="input_col_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                placeholder={t('Lastname', { ns: 'form' })}
                errors={errors.lastname?.message}
              />
            )}
            name="lastname"
            defaultValue=""
          />
        </div>
        <div className="input_col_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                placeholder={t('Phone number', { ns: 'form' })}
                errors={errors.phone_number?.message}
              />
            )}
            name="phone_number"
            defaultValue=""
          />
        </div>
        <div className="input_col_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextArea
                {...field}
                placeholder={t('Address', { ns: 'form' })}
                errors={errors.address?.message}
              />
            )}
            name="address"
            defaultValue=""
          />
        </div>
        <div className="input_col_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.DropdownSelect
                {...field}
                options={_provinceOption}
                placeholder={t('Province', { ns: 'form' })}
                errors={errors.province?.message}
                onChaneCustom={_handleChangeProvince}
              />
            )}
            name="province"
            defaultValue=""
          />
        </div>
        <div className="input_col_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.DropdownSelect
                {...field}
                isSplitLabel
                options={_districtOption}
                placeholder={t('District', { ns: 'form' })}
                errors={errors.district?.message}
                onChaneCustom={_handleChangeDistrict}
              />
            )}
            name="district"
            defaultValue=""
          />
        </div>
        <div className="input_col_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.DropdownSelect
                {...field}
                disabled
                options={_subdistrictOption}
                placeholder={t('Sub district', { ns: 'form' })}
                errors={errors.sub_district?.message}
                onChaneCustom={_handleChangeSubDistrict}
              />
            )}
            name="sub_district"
            defaultValue=""
          />
        </div>

        <div className="input_col_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                placeholder={t('Zip code', { ns: 'form' })}
                errors={errors.zipcode?.message}
              />
            )}
            name="zipcode"
            defaultValue=""
          />
        </div>
        <div className="input_col_wrap mb_8 pd_24">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.Checkbox
                {...field}
                disabled
                placeholder={
                  <>
                    {t('Accept the terms and conditions - Privacy Policy', {
                      ns: 'form',
                    })}
                    <span
                      className="highlight"
                      onClick={() => _handleClickEnbPAPD('accept_profile_info')}
                    >
                      {/* {t('More details', { ns: 'form' })} */}
                      คลิก
                    </span>
                  </>
                }
                // errors={errors.phone?.message}
              />
            )}
            name="agree_term"
            defaultValue={true}
          />
        </div>
        <div className="input_col_wrap mb_24 pd_24">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.Checkbox
                {...field}
                disabled
                placeholder={
                  <>
                    {t(
                      'Accept the terms and conditions of participation in the activity',
                      {
                        ns: 'form',
                      }
                    )}
                    <span
                      className="highlight"
                      onClick={() => _handleClickEnbPAPD('accept_pdpa')}
                    >
                      {/* {t('More details', { ns: 'form' })} */}
                      คลิก
                    </span>
                  </>
                }
                // errors={errors.phone?.message}
              />
            )}
            name="agree_term"
            defaultValue={true}
          />
        </div>
        <Buttons.BgStandard
          type="submit"
          label={t('Confirm', { ns: 'common' })}
        />
      </form>
      <Modals.BodyEmpty
        isShowModal={_isShowModal}
        onCloseModal={_handleCloseModal}
      >
        {_renderModal}
      </Modals.BodyEmpty>
    </ProfileFormStyled>
  );
};

ProfileForm.propTypes = {};

export default ProfileForm;
