import styled from 'styled-components';

export const ScanQRCodeStyled = styled.div`
  .scan_qr_container {
    border: 1px solid ${({ theme }) => theme.COLORS.GRAY_4};
    border-radius: 16px;
    height: 220px;
    background: ${({ theme }) => theme.COLORS.WHITE_2};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .scan_show {
    
  }
  .theme_standard {
  }
`;
