export default function renderIcon({ color = '#FF0000' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24.4"
      height="24.4"
      viewBox="0 0 57.828 57.841"
    >
      <g
        id="Group_617"
        data-name="Group 617"
        transform="translate(-724.27 -95.612)"
      >
        <path
          id="Path_1182"
          data-name="Path 1182"
          d="M738.46,108.9c.96.95,1.88,1.87,2.8,2.79,1.05,1.05,2.11,2.09,3.15,3.15a1.853,1.853,0,0,1-.28,2.84,2.028,2.028,0,0,1-1.18.33H726.21a1.859,1.859,0,0,1-1.91-1.52,2.421,2.421,0,0,1-.03-.47V99.28a1.861,1.861,0,0,1,3.25-1.33l5.47,5.47a2.125,2.125,0,0,1,.18.22c.09-.08.15-.13.21-.18a28.7,28.7,0,0,1,13.5-7.15,28.894,28.894,0,0,1,25.49,49.81,28.705,28.705,0,0,1-21.54,7.23,28.592,28.592,0,0,1-15.72-6.24,2.792,2.792,0,0,1-.28-4.24c.44-.45.88-.9,1.34-1.33a2.772,2.772,0,0,1,3.7-.17,22.164,22.164,0,0,0,3.86,2.43,21.491,21.491,0,0,0,22.74-2.44,20.6,20.6,0,0,0,7.82-13.1,20.912,20.912,0,0,0-4.84-17.69,20.456,20.456,0,0,0-12.37-7.12,21.11,21.11,0,0,0-17.47,4.46C739.23,108.22,738.86,108.56,738.46,108.9Z"
          fill={color}
        />
        <path
          id="Path_1183"
          data-name="Path 1183"
          d="M749.44,120.14v-7.69a2.782,2.782,0,0,1,2.17-2.76,1.127,1.127,0,0,1,.32-.05,25.6,25.6,0,0,1,2.63.04,2.71,2.71,0,0,1,2.33,2.59c.01.29.01.58.01.87v11.19a.45.45,0,0,0,.2.41c1.48,1.14,2.96,2.3,4.44,3.45a2.8,2.8,0,0,1,.52,4.12c-.37.48-.73.96-1.12,1.43a2.751,2.751,0,0,1-3.78.48c-1.48-1.11-2.93-2.26-4.38-3.39-1.04-.81-2.08-1.62-3.12-2.42a.518.518,0,0,1-.22-.46Q749.44,124.035,749.44,120.14Z"
          fill={color}
        />
      </g>
    </svg>
  );
}
