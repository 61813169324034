import { useEffect, useState } from "react";
// import PropTypes from 'prop-types'
import { NavigationBottomStyled } from "./styled";
import { Outlet } from "react-router-dom";
import { IMG_PATH } from "utils/constants/imgPath";
import { userService } from "apiServices";
import { NavigationBottomWidget } from "widgets";
import { Displays } from "components";

const NavigationBottom = () => {
  const [_isLoading, _setIsLoading] = useState(true);
  const [_systemConfig, _setSystemConfig] = useState();

  useEffect(() => {
    _fetchData();
  }, []);

  const _fetchData = async () => {
    let res = await userService.GET_SYSTEM_CONFIG();
    if (res && res.status === 200 && res.data.docs.length > 0) {
      console.log("_fetchData", res);
      _setSystemConfig(res.data.docs[0]);
      _setIsLoading(false);
    } else {
      _setIsLoading(false);
    }
  };

  return (
    <NavigationBottomStyled
      bgUrl={
        _systemConfig && _systemConfig?.bg_img_path
          ? IMG_PATH + _systemConfig?.bg_img_path
          : false
      }
    >
      {_isLoading ? (
        <Displays.Loading theme_standard_loading />
      ) : (
        <>
          <div className="page_header_layout">
            <Displays.LogoHeader />
          </div>
          <div className="main_content_layout">
            <Outlet />
          </div>
          <div className="navigation_bottom_layout">
            <NavigationBottomWidget />
          </div>
        </>
      )}
    </NavigationBottomStyled>
  );
};

NavigationBottom.propTypes = {};

export default NavigationBottom;
