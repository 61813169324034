import styled from 'styled-components';

export const BgStandardStyled = styled.div`
  .btn {
    border-radius: 6px;
    padding: 8px 12px;
    width: 100%;
    background: ${({ theme }) => theme.COLORS.RED_1};
    font-size: ${({ theme }) => theme.FONT.SIZE.S18};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
    color: ${({ theme }) => theme.COLORS.WHITE_2};
    cursor: pointer;
  }

  .theme_cancel {
    .btn {
      background: ${({ theme }) => theme.COLORS.BLUE_1};
    }
  }
`;
