import React from 'react';
import { withTranslation } from 'react-i18next';
import { NewDetailContainerStyled } from './styled';
import { withRouter } from 'navigations/withRouter';
import { userService } from 'apiServices';
import { IMG_PATH } from 'utils/constants/imgPath';
import { Displays } from 'components';

class NewDetailContainer extends React.Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    let res = await userService.GET_NEW_DETAIL(this.props.router.params?.id);
    if (res && res.status === 200) {
      this.setState({
        isLoading: false,
        data: res.data,
      });
    }
  };

  render() {
    const { isLoading, data } = this.state;
    return (
      <NewDetailContainerStyled useSuspense={false}>
        {isLoading ? (
          <div className="loading_show">
            <Displays.Loading />
          </div>
        ) : (
          <>
            <div className="new_img_wrap">
              <img
                src={data.img_path ? IMG_PATH + data.img_path : ''}
                alt="news"
              />
            </div>
            <div className="new_content">
              <div className="title">{data.title}</div>
              <div className="sub_title">{data.description}</div>
            </div>
          </>
        )}
      </NewDetailContainerStyled>
    );
  }
}

export default withTranslation('common')(withRouter(NewDetailContainer));
