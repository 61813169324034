import styled from 'styled-components';

export const TabStyled = styled.div`
  .tab_container {
    border-radius: 12px;
    overflow: hidden;
    background: ${({ theme }) => theme.COLORS.WHITE_2};
    .tab_header_sec {
      display: flex;
      .tb_h_col {
        flex: 1;
        padding: 16px 0;
        background: ${({ theme }) => theme.COLORS.WHITE_2};
        text-align: center;
        font-size: ${({ theme }) => theme.FONT.SIZE.S18};
        font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
        color: ${({ theme }) => theme.COLORS.RED_1};
        cursor: pointer;
        &.active {
          background: ${({ theme }) => theme.COLORS.RED_1};
          color: ${({ theme }) => theme.COLORS.WHITE_2};
        }
      }
    }
    .tab_body_sec {
      padding: 12px;
      min-height: 450px;
    }
  }

  .theme_standard {
  }
`;
