import styled from 'styled-components';

export const IconBgStandardStyled = styled.div`
  .btn {
    border-radius: 6px;
    padding: 8px 12px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    background: ${({ theme, disabled }) =>
      !disabled ? theme.COLORS.RED_1 : theme.COLORS.GRAY_2};
    font-size: ${({ theme }) => theme.FONT.SIZE.S15};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
    color: ${({ theme }) => theme.COLORS.WHITE_2};
    cursor: pointer;
  }

  .theme_cancel {
    .btn {
      background: ${({ theme }) => theme.COLORS.BLUE_1};
    }
  }
`;
