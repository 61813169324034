import styled from 'styled-components';

export const DeliveryStepWidgetStyled = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .tracking_line_status {
    position: absolute;
    top: 25px;
    left: 0;
    right: 0;
    margin: auto;
    width: 80%;
    height: 2px;
    background: ${({ theme }) => theme.COLORS.GRAY_5};
    z-index: 1;
  }
  .tracking_status_item {
    z-index: 2;
    .ts_icon {
      margin: auto;
      margin-bottom: 4px;
      border-radius: 52px;
      width: 52px;
      height: 52px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: ${({ theme }) => theme.COLORS.GRAY_2};
      &.active {
        background: ${({ theme }) => theme.COLORS.RED_1};
      }
    }
    .ts_label {
      text-align: center;
      color: ${({ theme }) => theme.COLORS.GRAY_5};
    }
  }
`;
