import styled from 'styled-components';

export const ExchangeRedeemDetailFormStyled = styled.div`
  padding: 16px;
  border-radius: 17px;
  width: 350px;
  max-height: 540px;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  ::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }
  ::-webkit-scrollbar-thumb {
    background: #484747;
    border: 0px none #ffffff;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #ffffff;
  }
  ::-webkit-scrollbar-thumb:active {
    background: #787878;
  }
  ::-webkit-scrollbar-track {
    background: #d2d0d0;
    border: 0px none #ffffff;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track:hover {
    background: #666666;
  }
  ::-webkit-scrollbar-track:active {
    background: #5a5858;
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }

  background: ${({ theme }) => theme.COLORS.WHITE_1};
  .input_col_wrap {
    margin-bottom: 16px;
    &.mb_8 {
      margin-bottom: 8px;
    }
    &.mb_22 {
      margin-bottom: 22px;
    }
    &.pl_16 {
      padding-left: 16px;
    }
    &.bText {
      color: ${({ theme }) => theme.COLORS.BLACK_1};
      font-size: ${({ theme }) => theme.FONT.SIZE.S20};
      &.red {
        color: ${({ theme }) => theme.COLORS.RED_1};
      }
    }
  }
  .gorup_btn_action {
    margin: 0 -8px;
    display: flex;
    justify-content: space-between;
    .redeem_action_col {
      flex: 1;
      margin: 0 8px;
    }
  }
`;
