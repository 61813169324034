import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { ProductItemStyled } from './styled';

const ProductItem = ({
  theme_standard,
  name,
  imgUri,
  description,
  onClick,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  return (
    <ProductItemStyled onClick={onClick}>
      <div className={customClass}>
        <div className="product_item_row">
          <div className="item_left_col">
            <img src={imgUri} alt="reward item" />
          </div>
          <div className="item_right_col">
            <div className="pi_title">{name}</div>
            <div className="pi_sub_title">{description}</div>
          </div>
        </div>
      </div>
    </ProductItemStyled>
  );
};

ProductItem.propTypes = {};

export default ProductItem;
