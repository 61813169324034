import React from 'react';
import { withTranslation } from 'react-i18next';
import { CounponContainerStyled } from './styled';
import { IMG_PATH } from 'utils/constants/imgPath';
import { Cards } from 'components';

class CounponContainer extends React.Component {
  render() {
    const { t, data, onClickReward } = this.props;
    return (
      <CounponContainerStyled useSuspense={false}>
        {!data || data.length === 0 ? (
          <div className="no_empty">ไม่มีของรางวัล</div>
        ) : (
          data.map((e, i) => (
            <div key={i}>
              <div
                className="product_item_wrap"
                onClick={() => onClickReward(e)}
              >
                <Cards.ProductReward
                  url={
                    e.img_path
                      ? IMG_PATH + e.img_path
                      : '/assets/images/mockup/product-coupon.png'
                  }
                  name={e.name}
                  price={'มูลค่า ' + e.total_value + ' ฿'}
                  point={t('Use points', {
                    ns: 'redeem',
                    point: e.redeem_point,
                  })}
                />
              </div>
              {data.length !== i + 1 && <div className="line_between_item" />}
            </div>
          ))
        )}
      </CounponContainerStyled>
    );
  }
}

export default withTranslation(['redeem'])(CounponContainer);
