export const ROUTE_PATH = {
  ROOT: '/',
  SPLASH: '/splash',
  LOGIN: '/logins',
  REGISTER: '/register',
  REGISTER_SUCCESS: '/registersuccess',
  SYSTEM: '/system',
  PRODUCT_SCAN_QR_CODE: '/system/register/productscanqrcode',
  PRODUCT_REGISTER: '/system/register/productregister',
  PRODUCT_REGISTER_DETAIL: '/system/register/productregister/:id',
  REWARD: '/system/register/reward',
  NEWS: '/system/news',
  NEWS_DETAIL: '/system/news/:id',
  REDEEM_REWARD: '/system/redeemreward',
  MESSAGE: '/system/redeemreward/message',
  HISTORY: '/system/history',
  PROFILE: '/system/profile',
};
