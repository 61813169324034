import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
    overflow: hidden;
    body {
      margin: 0;
      font-family: kanit_light;
      font-size: 14px;
      color: #1A1A1A;
      height: 100%;
      width: 100%;
      position: fixed;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;  
      -ms-overflow-style: none; /* for Internet Explorer, Edge */
      scrollbar-width: none; /* for Firefox */
      overflow-y: scroll;
      ::-webkit-scrollbar {
        display: none; /* for Chrome, Safari, and Opera */
      }
    }
     button {
      border: none; 
      &:focus {
        outline: none;
      }
    }
    input {
      &:focus {
        outline: none;
      }
    }
  }
`;
