export default function renderIcon({ color = '#fff' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32.661"
      height="24.618"
      viewBox="0 0 32.661 24.618"
    >
      <path
        id="Path_842"
        data-name="Path 842"
        d="M39.891,56.313a5.219,5.219,0,0,1,.551-.826q7.142-7.166,14.32-14.3a4.768,4.768,0,0,1,2-1.194,3.3,3.3,0,0,1,3.72,1.741,3.41,3.41,0,0,1-.629,4.013c-2.137,2.171-4.3,4.314-6.455,6.469Q47.94,57.683,42.476,63.142c-1.834,1.828-3.671,1.821-5.49,0q-3.871-3.873-7.736-7.751a3.322,3.322,0,0,1-.688-3.872,3.429,3.429,0,0,1,5.642-.863c1.616,1.632,3.245,3.251,4.87,4.874.2.2.412.4.817.783"
        transform="translate(-28.217 -39.893)"
        fill={color}
      />
    </svg>
  );
}
