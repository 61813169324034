import { default as TextArea } from './TextArea';
import { default as TextField } from './TextField';
import { default as Dropdown } from './Dropdown';
import { default as DropdownSelect } from './DropdownSelect';
import { default as Checkbox } from './Checkbox';
import { default as ScanQRCode } from './ScanQRCode';
import { default as Upload } from './Upload';
import { default as AvatarUpload } from './AvatarUpload';
import { default as Radio } from './Radio';

const EXPORT = {
  TextArea,
  TextField,
  Dropdown,
  DropdownSelect,
  Checkbox,
  ScanQRCode,
  Upload,
  AvatarUpload,
  Radio,
};

export default EXPORT;
