import React from 'react';
import { PDPAFormStyled } from './styled';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Buttons, Inputs } from 'components';

const PDPAForm = ({ isRead, onSubmit, onCancel }) => {
  const { t } = useTranslation(['form']);
  const schema = yup.object().shape({
    is_agree_term_confirm: yup
      .bool()
      .nullable()
      .oneOf([true], t('Please accept condition', { ns: 'form' })),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <PDPAFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="pdpa_title">
          {t('Verify your identity', { ns: 'form' })}
        </div>
        <div className="pdpa_content">
          xxxxxxxxxxxxxxxx xxxxxxxxxxxxxx xxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
          xxxxxxxxxxxxxxxx xxxxxxxxxxxxxx xxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
          xxxxxxxxxxxxxxxx xxxxxxxxxxxxxx xxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
          xxxxxxxxxxxxxxxx xxxxxxxxxxxxxx xxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
          xxxxxxxxxxxxxxxx xxxxxxxxxxxxxx xxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
        </div>
        {isRead ? (
          <div className="pdpa_cancel">
            *** {t('Please contact Smp Support to cancel', { ns: 'form' })}
          </div>
        ) : (
          <div className="pdpa_input_wrap">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.Checkbox
                  {...field}
                  theme_gray
                  placeholder={t(
                    'Accept the terms and conditions - Privacy Policy',
                    { ns: 'form' }
                  )}
                  errors={errors.is_agree_term_confirm?.message}
                />
              )}
              name="is_agree_term_confirm"
              defaultValue={false}
            />
          </div>
        )}

        {isRead ? (
          <Buttons.BgStandard
            label={t('Back', { ns: 'common' })}
            onClick={onCancel}
          />
        ) : (
          <div className="pdpa_action_row">
            <div className="pdpa_action_col">
              <Buttons.BgStandard
                theme_cancel
                label={t('Cancel', { ns: 'common' })}
                onClick={onCancel}
              />
            </div>
            <div className="pdpa_action_col">
              <Buttons.BgStandard
                type="submit"
                label={t('OK', { ns: 'common' })}
              />
            </div>
          </div>
        )}
      </form>
    </PDPAFormStyled>
  );
};

PDPAForm.propTypes = {};

export default PDPAForm;
