import styled from 'styled-components';

export const RegisterStoreInfomationContainerStyled = styled.div`
  .loading_show {
    height: 45vh;
  }
  .regis_info_title {
    margin-bottom: 16px;
  }
`;
