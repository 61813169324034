export default function renderIcon({ color = '#5bb138' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="25.199"
      height="25.201"
      viewBox="0 0 25.199 25.201"
    >
      <defs>
        <clipPath id="clipPath">
          <rect
            id="Rectangle_305"
            data-name="Rectangle 305"
            width="25.199"
            height="25.201"
            fill="none"
          />
        </clipPath>
      </defs>
      <g id="Group_328" data-name="Group 328" transform="translate(0 0)">
        <g
          id="Group_327"
          data-name="Group 327"
          transform="translate(0 0)"
          clipPath="url(#clipPath)"
        >
          <path
            id="Path_831"
            data-name="Path 831"
            d="M0,12.584A12.6,12.6,0,1,1,12.584,25.2,12.614,12.614,0,0,1,0,12.584m9.933,2.378c-.232-.222-.352-.333-.468-.448-.93-.929-1.862-1.856-2.787-2.79a1.963,1.963,0,0,0-3.229.494,1.9,1.9,0,0,0,.394,2.216q2.207,2.225,4.427,4.436a1.967,1.967,0,0,0,3.142,0q3.13-3.121,6.252-6.25c1.232-1.233,2.471-2.459,3.694-3.7a1.951,1.951,0,0,0,.359-2.3,1.89,1.89,0,0,0-2.129-1,2.723,2.723,0,0,0-1.146.683q-4.12,4.068-8.194,8.181a2.972,2.972,0,0,0-.315.472"
            transform="translate(0 0)"
            fill={color}
          />
          <path
            id="Path_832"
            data-name="Path 832"
            d="M14.137,22.155a2.972,2.972,0,0,1,.315-.472q4.087-4.1,8.194-8.181a2.723,2.723,0,0,1,1.146-.683,1.89,1.89,0,0,1,2.129,1,1.951,1.951,0,0,1-.359,2.3c-1.223,1.242-2.462,2.469-3.694,3.7q-3.126,3.126-6.252,6.25a1.967,1.967,0,0,1-3.142,0q-2.215-2.216-4.427-4.436a1.9,1.9,0,0,1-.394-2.216,1.963,1.963,0,0,1,3.229-.494c.925.934,1.857,1.861,2.787,2.79.116.115.236.226.468.448"
            transform="translate(-4.203 -7.193)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
}
