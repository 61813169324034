import styled from 'styled-components';

export const HeaderRowPanelStyled = styled.div`
  .table_container {
    border-radius: 16px;
    padding: 16px 8px;
    background: ${({ theme }) => theme.COLORS.WHITE_2};
    .table_title {
      border-bottom: 1px solid ${({ theme }) => theme.COLORS.GRAY_4};
      padding-bottom: 12px;
      text-align: center;
      font-size: ${({ theme }) => theme.FONT.SIZE.S20};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
      color: ${({ theme }) => theme.COLORS.BLACK_2};
    }
    .table_header {
      background: ${({ theme }) => theme.COLORS.ORANGE_1};
      .header_row {
        max-width: 1200px;
        width: 100%;
        margin: auto;
        display: flex;
        align-items: center;
        .header_col {
          width: 100%;
          padding: 15px 6px;
          font-size: ${({ theme }) => theme.FONT.SIZE.S14};
          font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_LIGHT};
          color: ${({ theme }) => theme.COLORS.BLUE_10};
          &:first-child {
            border-left: none;
          }
        }
      }
    }
    .table_body {
      .label_empty {
        height: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .body_row_wrap {
        .body_row {
          max-width: 1200px;
          width: 100%;
          margin: auto;
          display: flex;
          align-items: center;
          cursor: pointer;
          padding: 12px 0;
          align-items: center;
          .body_col {
            font-size: ${({ theme }) => theme.FONT.SIZE.S12};
            font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_LIGHT};
            color: ${({ theme }) => theme.COLORS.GRAY_5};
            padding: 0 6px;
            width: 100%;
          }
        }
        &:hover {
          background: ${({ theme }) => theme.COLORS.GRAY_5}0D;
        }
      }
    }
  }

  .theme_standard {
  }
`;
