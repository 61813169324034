import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'navigations/withRouter';
import { RedeemRewardContainerStyled } from './styled';
import { userService } from 'apiServices';
import { CounponContainer, RewardPremium } from './Tabs';
import { ROUTE_PATH } from 'utils/constants/routePath';
import { toast } from 'react-toastify';
import { RedeemReward, RewardCodeScan } from './Modals';
import { setReduxAuthen } from 'store/actions';
import { IMG_PATH } from 'utils/constants/imgPath';
import { Displays, Navigations, Modals, Forms, Buttons } from 'components';

class RedeemRewardContainer extends React.Component {
  state = { isLoading: true };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    let resArr = await Promise.all([
      this.fetchReward(),
      this.fetchProfilePoint(),
    ]);
    if (resArr) {
      this.props.setReduxAuthen(resArr[1]);
      this.setState({
        isLoading: false,
        dataCopon: resArr[0].standard,
        dataPremium: resArr[0].premium,
      });
    }
  };

  fetchReward = async () => {
    let res = await userService.GET_REWARD_LIST(
      `?use_condition=2&is_active=true&pagination=false`
    );
    if (res && res.status === 200) {
      return res.data.docs.reduce(
        (result, data) => {
          if (data.reward_cate === '1') {
            result.standard.push(data);
          } else {
            result.premium.push(data);
          }
          return result;
        },
        { standard: [], premium: [] }
      );
    }
  };

  fetchProfilePoint = async () => {
    let res = await userService.GET_MY_PROFILE();
    if (res && res.status === 200) {
      return res.data;
    }
  };

  handleClickReward = (e) => {
    this.setState({
      isShowModal: true,
      dataReward: e,
      renderModal: (
        <RedeemReward
          imgUri={IMG_PATH + e.img_path}
          name={e.name}
          rewardDate={e.date}
          reward_cate={e.reward_cate}
          description={e.description}
          total_value={e.total_value}
          memo={e.memo}
          onBack={this.handleCloseModal}
          // onRedeemSuccess={this.handleExchangeAddress}
          onRedeemSuccess={this.handleClickExchange}
        />
      ),
    });
  };

  handleClickExchange = () => {
    const { dataReward } = this.state;
    switch (dataReward.reward_type) {
      case '1':
        this.onBarcodeRewardTypeFlow(dataReward);
        break;
      case '2':
        this.onProductRewardTypeFlow(dataReward);
        break;
      default:
        break;
    }
  };

  // BARCODE
  onBarcodeRewardTypeFlow = async () => {
    const { authenRedux, t } = this.props;
    const { dataReward } = this.state;
    this.setState({
      isBgLoading: true,
    });
    let params = {
      reward_ref_id: dataReward._id,
    };
    let res = await userService.POST_REWARD_REDEEM(params);
    if (res && res.status === 200) {
      this.props.setReduxAuthen({
        ...authenRedux,
        total_reward_point: res.data.total_reward_point,
      });
      let params = {
        reward_history_id: res.data._id,
      };
      let resBarcode = await userService.POST_REWARD_ACTIVE_BARCODE(params);
      if (resBarcode && resBarcode.status === 200) {
        this.fetchData();
        this.setState({
          isBgLoading: false,
          renderModal: (
            <RewardCodeScan
              {...dataReward}
              barcode_no={resBarcode.data.barcode_no}
              onBack={this.handleCloseModal}
              _onTimeoutBarcode={this.handleCloseModal}
            />
          ),
        });
      }
    } else {
      this.setState({
        isBgLoading: false,
        isShowModal: false,
      });
      toast.error(t('Failed to redeem reward', { ns: 'form' }));
    }
  };

  // PRODUCK
  onProductRewardTypeFlow = () => {
    const { authenRedux } = this.props;
    this.setState({
      isShowModal: true,
      renderModal: (
        <Forms.ExchangeRedeemDetailForm
          authenRedux={authenRedux}
          onBack={this.handleCloseModal}
          onSubmit={this.handleRedeemSuccess}
        />
      ),
    });
  };
  handleRedeemSuccess = async (value) => {
    const { authenRedux, t } = this.props;
    const { dataReward } = this.state;
    let params = {
      reward_ref_id: dataReward._id,
      phone_number:
        value.address_select === '1'
          ? authenRedux.phone_number
          : value.phone_number,
      address:
        value.address_select === '1' ? authenRedux.address : value.address,
      address_province:
        value.address_select === '1'
          ? authenRedux.province
          : value.province.value,
      address_district:
        value.address_select === '1'
          ? authenRedux.district
          : value.district.value,
      address_sub_district:
        value.address_select === '1'
          ? authenRedux.sub_district
          : value.sub_district.value,
      address_zipcode:
        value.address_select === '1' ? authenRedux.zipcode : value.zipcode,
      firstname_shipping:
        value.address_select === '1' ? authenRedux.firstname : value.firstname,
      lastname_shipping:
        value.address_select === '1' ? authenRedux.lastname : value.lastname,
      phone_number_shipping:
        value.address_select === '1'
          ? authenRedux.phone_number
          : value.phone_number,
    };

    let res = await userService.POST_REWARD_REDEEM(params);
    if (res && res.status === 200) {
      toast.success(t('Successful redemption', { ns: 'form' }));
      this.props.setReduxAuthen({
        ...authenRedux,
        total_reward_point: res.data.total_reward_point,
      });
      this.props.router.navigate(ROUTE_PATH.MESSAGE);
    } else {
      toast.error(t('Failed to redeem reward', { ns: 'form' }));
    }
    this.setState({
      isShowModal: false,
    });
  };

  handleCloseModal = () => {
    this.setState({
      isShowModal: false,
    });
  };

  handleClickMyReward = () => {
    this.props.router.navigate(ROUTE_PATH.MESSAGE);
  };

  render() {
    const { t, authenRedux } = this.props;
    const {
      isLoading,
      isBgLoading,
      dataCopon,
      dataPremium,
      isShowModal,
      renderModal,
    } = this.state;
    return (
      <RedeemRewardContainerStyled useSuspense={false}>
        <Displays.BGLoading visible={isBgLoading} />
        {isLoading ? (
          <div className="loading_show">
            <Displays.Loading />
          </div>
        ) : (
          <>
            <div className="point_row">
              <div className="point_col point_line">
                <Displays.LabelPointVertical
                  label={t('Point')}
                  point={
                    authenRedux.is_main_branch
                      ? authenRedux?.total_reward_point
                      : authenRedux?.my_reward_point
                  }
                />
              </div>
              <div className="point_col " onClick={this.handleClickMyReward}>
                {/* <Displays.LabelPointVertical
                  label={'รางวัลของฉัน'}
                  // point={'10'}
                /> */}
                <div className="my_reward_wrap">
                  <Buttons.BgStandard label={'รางวัลของฉัน'} />
                </div>
              </div>
            </div>
            {authenRedux.is_main_branch && (
              <Navigations.Tab
                tab={tab({
                  dataCopon,
                  dataPremium,
                  handleClickReward: this.handleClickReward,
                })}
                initialValue={'counpon'}
              />
            )}
          </>
        )}
        <Modals.BodyEmpty
          isShowModal={isShowModal}
          onCloseModal={this.handleCloseModal}
        >
          {renderModal}
        </Modals.BodyEmpty>
      </RedeemRewardContainerStyled>
    );
  }
}

const tab = ({ dataCopon, dataPremium, handleClickReward }) => ({
  counpon: {
    key: 'counpon',
    label: 'Coupon',
    element: (
      <CounponContainer data={dataCopon} onClickReward={handleClickReward} />
    ),
  },
  rewardPremium: {
    key: 'rewardPremium',
    label: 'Reward Premium',
    element: (
      <RewardPremium data={dataPremium} onClickReward={handleClickReward} />
    ),
  },
});

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxAuthen: (data) => dispatch(setReduxAuthen(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation(['redeem'])(withRouter(RedeemRewardContainer)));
