import React, { useEffect, useState } from 'react';
import { ExchangeRedeemDetailFormStyled } from './styled';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import address from 'utils/jsons/thailandAddress/address.json';
import { Inputs, Buttons } from 'components';

const ExchangeRedeemDetailForm = ({ onSubmit, authenRedux, onBack }) => {
  const { t } = useTranslation(['form', 'common']);

  const schema = yup.object().shape({
    firstname: yup.string().when('address_select', {
      is: (aCheckbox) => aCheckbox === '2',
      then: yup.string().required(t('Please enter firstname', { ns: 'form' })),
    }),
    lastname: yup.string().when('address_select', {
      is: (aCheckbox) => aCheckbox === '2',
      then: yup.string().required(t('Please enter lastname', { ns: 'form' })),
    }),
    phone_number: yup.string().when('address_select', {
      is: (aCheckbox) => aCheckbox === '2',
      then: yup
        .string()
        .required(t('Please enter phone number', { ns: 'form' })),
    }),
    address: yup.string().when('address_select', {
      is: (aCheckbox) => aCheckbox === '2',
      then: yup.string().required(t('Please enter address', { ns: 'form' })),
    }),
    province: yup.object().when('address_select', {
      is: (aCheckbox) => aCheckbox === '2',
      then: yup
        .object()
        .required(t('Please select province', { ns: 'form' }))
        .nullable(),
    }),
    district: yup.object().when('address_select', {
      is: (aCheckbox) => aCheckbox === '2',
      then: yup
        .object()
        .required(t('Please select district', { ns: 'form' }))
        .nullable(),
    }),

    sub_district: yup.object().when('address_select', {
      is: (aCheckbox) => aCheckbox === '2',
      then: yup
        .object()
        .required(t('Please select sub district', { ns: 'form' }))
        .nullable(),
    }),
    zipcode: yup.string().when('address_select', {
      is: (aCheckbox) => aCheckbox === '2',
      then: yup.string().required(t('Please enter zipcode', { ns: 'form' })),
    }),
    address_select: yup
      .string()
      .nullable()
      .required(t('Please select delivery address', { ns: 'form' })),
  });

  const {
    control,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const watchIsAddressSelect = watch('address_select');

  const [_provinceOption, _setProvinceOption] = useState([]);
  const [_districtOption, _setDistrictOption] = useState([]);
  const [_subdistrictOption, _setSubdistrictOption] = useState([]);

  useEffect(() => {
    _setProvinceOption(
      address.reduce((result, data, i) => {
        if (i === 0) {
          result.push({ label: data.province, value: data.province });
        } else {
          let find = result.find((e) => e.value === data.province);
          if (!find) {
            result.push({ label: data.province, value: data.province });
          }
        }
        return result;
      }, [])
    );
  }, []);

  const _handleChangeProvince = (e) => {
    setValue('district', '');
    _setDistrictOption(
      address.reduce((result, data, i) => {
        if (data.province === e.value) {
          // let find = result.find((e) => e.value === data.amphoe);
          // if (!find) {
          result.push({
            label: `${data.amphoe} ${data.district} ${data.zipcode}`,
            value: data.amphoe,
          });
          // }
        }
        return result;
      }, [])
    );
  };

  const _handleChangeDistrict = (e) => {
    let splitData = e.label.split(' ');
    setValue('sub_district', { label: splitData[1], value: splitData[1] });
    setValue('zipcode', splitData[2]);
    _setSubdistrictOption(
      address.reduce((result, data, i) => {
        if (data.amphoe === e.value) {
          let find = result.find((e) => e.value === data.district);
          if (!find) {
            result.push({
              label: data.district,
              value: data.district,
              zipcode: data.zipcode,
            });
          }
        }
        return result;
      }, [])
    );
  };

  const _handleChangeSubDistrict = (e) => {
    let find = _subdistrictOption.find((f) => f.value === e.value);
    if (find) {
      setValue('zipcode', find.zipcode);
    }
  };

  return (
    <ExchangeRedeemDetailFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* <div className="input_col_wrap bText red">
          {t('Amount', { ns: 'form' })}
        </div>
        <div className="input_col_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                type='number'
                min={1}
                placeholder={t('Amount', { ns: 'form' })}
                errors={errors.redeem_point?.message}
              />
            )}
            name="redeem_point"
            defaultValue=""
          />
        </div> */}
        <div className="input_col_wrap bText">
          {t('Confirm delivery address', { ns: 'form' })}
        </div>
        <div className="input_col_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.Radio
                {...field}
                theme_black_regular
                options={[
                  {
                    label: t('Current address', { ns: 'form' }),
                    value: 1,
                    subDetail: `${authenRedux?.address} ${' ต.'} ${
                      authenRedux?.sub_district
                    } ${'อ. '} ${authenRedux?.district} ${'จ. '} ${
                      authenRedux?.province
                    } ${' '} ${authenRedux?.zipcode}`,
                  },
                  {
                    label: t('Delivery to another address (please specify)', {
                      ns: 'form',
                    }),
                    value: 2,
                  },
                ]}
                // placeholder={t('test')}
                errors={errors.address_select?.message}
              />
            )}
            name="address_select"
            defaultValue=""
          />
        </div>
        {watchIsAddressSelect === '2' && (
          <>
            <div className="input_col_wrap">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.TextField
                    {...field}
                    placeholder={t('Name', { ns: 'form' })}
                    errors={errors.firstname?.message}
                  />
                )}
                name="firstname"
                defaultValue=""
              />
            </div>
            <div className="input_col_wrap">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.TextField
                    {...field}
                    placeholder={t('Lastname', { ns: 'form' })}
                    errors={errors.lastname?.message}
                  />
                )}
                name="lastname"
                defaultValue=""
              />
            </div>
            <div className="input_col_wrap">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.TextField
                    {...field}
                    type="tel"
                    placeholder={t('Phone number', { ns: 'form' })}
                    errors={errors.phone_number?.message}
                  />
                )}
                name="phone_number"
                defaultValue=""
              />
            </div>
            <div className="input_col_wrap">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.TextField
                    {...field}
                    placeholder={t('AddressEtc', { ns: 'form' })}
                    errors={errors.address?.message}
                  />
                )}
                name="address"
                defaultValue=""
              />
            </div>
            <div className="input_col_wrap">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.Dropdown
                    {...field}
                    options={_provinceOption}
                    placeholder={t('Province', { ns: 'form' })}
                    errors={errors.province?.message}
                    onChaneCustom={_handleChangeProvince}
                  />
                )}
                name="province"
                defaultValue=""
              />
            </div>
            <div className="input_col_wrap">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.Dropdown
                    {...field}
                    options={_districtOption}
                    placeholder={t('District', { ns: 'form' })}
                    errors={errors.district?.message}
                    onChaneCustom={_handleChangeDistrict}
                  />
                )}
                name="district"
                defaultValue=""
              />
            </div>
            <div className="input_col_wrap">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.Dropdown
                    {...field}
                    disabled
                    options={_subdistrictOption}
                    placeholder={t('Sub district', { ns: 'form' })}
                    errors={errors.sub_district?.message}
                    onChaneCustom={_handleChangeSubDistrict}
                  />
                )}
                name="sub_district"
                defaultValue=""
              />
            </div>
            <div className="input_col_wrap">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.TextField
                    {...field}
                    disabled
                    placeholder={t('Zip code', { ns: 'form' })}
                    errors={errors.zipcode?.message}
                  />
                )}
                name="zipcode"
                defaultValue=""
              />
            </div>
          </>
        )}
        {/* <Buttons.BgStandard type="submit" label={t('OK', { ns: 'common' })} /> */}
        <div className="gorup_btn_action">
          <div className="redeem_action_col">
            <Buttons.BgStandard
              theme_cancel
              label={t('Back', { ns: 'common' })}
              onClick={onBack}
            />
          </div>
          <div className="redeem_action_col">
            <Buttons.BgStandard
              label={t('OK', { ns: 'common' })}
              type="submit"
              // onClick={onRedeemSuccess}
            />
          </div>
        </div>
      </form>
    </ExchangeRedeemDetailFormStyled>
  );
};

ExchangeRedeemDetailForm.propTypes = {};

export default ExchangeRedeemDetailForm;
