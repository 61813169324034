import React from 'react';
import { withTranslation } from 'react-i18next';
import { RegisterContainerStyled } from './styled';
import {
  RegisterTypeStoreContainer,
  RegisterStoreInfomationContainer,
} from './Steps';

class RegisterContainer extends React.Component {
  state = {
    step: 1,
  };

  renderStep = () => {
    const { step, storeType } = this.state;
    switch (step) {
      case 1:
        return (
          <RegisterTypeStoreContainer
            onSubmitSuccess={this.handleSubmitRegisTypeStoreSuccess}
          />
        );
      case 2:
        return <RegisterStoreInfomationContainer storeType={storeType} />;
      default:
        break;
    }
  };

  handleSubmitRegisTypeStoreSuccess = (values) => {
    this.setState({
      step: 2,
      storeType: values.storeType,
    });
  };

  render() {
    return (
      <RegisterContainerStyled useSuspense={false}>
        {this.renderStep()}
      </RegisterContainerStyled>
    );
  }
}

export default withTranslation('common')(RegisterContainer);
