export default function renderIcon({ color = '#fff' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29.159"
      height="30.357"
      viewBox="0 0 29.159 30.357"
    >
      <path
        id="Path_834"
        data-name="Path 834"
        d="M50.385,62.824h-.961c-.309-.164-.607-.357-.93-.489-3.986-1.637-7.965-3.291-11.972-4.877a1.655,1.655,0,0,1-1.2-1.789q.038-8.095,0-16.19a1.654,1.654,0,0,1,1.2-1.789c4.191-1.662,8.347-3.412,12.539-5.072a2.517,2.517,0,0,1,1.691-.006c4.193,1.657,8.353,3.4,12.539,5.073a1.6,1.6,0,0,1,1.194,1.675q-.037,8.214,0,16.429A1.487,1.487,0,0,1,63.4,57.413c-.941.328-1.852.74-2.772,1.125q-5.121,2.14-10.24,4.286m.482-2.375c3.826-1.559,7.482-3.027,11.107-4.566a1.437,1.437,0,0,0,.549-1.12c.038-4.48.026-8.961.019-13.441a7.873,7.873,0,0,0-.122-.946c-3.706,1.505-7.293,2.947-10.858,4.439a1.257,1.257,0,0,0-.665.9c-.042,4.835-.029,9.67-.029,14.731m-13.6-20.13c0,5.1-.011,9.967.03,14.836,0,.271.387.651.684.789,1.121.518,2.279.959,3.424,1.425,2.466,1,4.933,2,7.537,3.061,0-5.065,0-9.972-.017-14.88a.817.817,0,0,0-.389-.618c-1.594-.686-3.207-1.328-5.028-2.071,0,1.374,0,2.51,0,3.646,0,.711-.046,1.479-.979,1.453-.911-.025-.952-.8-.949-1.5,0-1.19.053-2.384-.031-3.568A1.473,1.473,0,0,0,40.9,41.83c-1.1-.543-2.263-.955-3.633-1.511m7.818.967c2.713,1.5,5.259,2.752,8.116.792a6.44,6.44,0,0,1,1.214-.5c2.136-.878,4.271-1.757,6.622-2.724-1.76-.725-3.259-1.373-4.786-1.949a1.669,1.669,0,0,0-1.126.053c-3.3,1.4-6.592,2.836-10.04,4.329M52.921,35.8l.024-.368c-1.119-.327-2.42-1.189-3.324-.887-3.619,1.211-7.113,2.783-10.845,4.3,1.18.488,2.151.922,3.149,1.281a1.532,1.532,0,0,0,1.023-.012C46.28,38.7,49.6,37.244,52.921,35.8"
        transform="translate(-35.324 -32.468)"
        fill={color}
      />
    </svg>
  );
}
