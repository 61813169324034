import React from 'react';
// import PropTypes from 'prop-types'
import { TrackingRewardStyled } from './styled';
import { useTranslation } from 'react-i18next';
// import { DeliveryStepWidget } from 'widgets';
import { Buttons, Icons } from 'components';

const TrackingReward = ({
  name,
  discount,
  imgUri,
  rewardDate,
  shippingBrand,
  shippingPhoneNumber,
  shippingLink,
  shipping_ref_no,
  // status,
  onBack,
}) => {
  const { t } = useTranslation(['message', 'common']);

  return (
    <TrackingRewardStyled>
      <div className="tracking_action_tile">
        {t('Action', { ns: 'message' })}
      </div>
      <div className="reward_name">{name}</div>
      <div className="reward_price">
        {t('Worth baht', { ns: 'message', bath: discount })}
      </div>
      <img
        className="tracking_reward_img"
        src={imgUri ? imgUri : ''}
        alt="reward product"
      />
      <div className="tracking_container">
        <div className="tracking_row">
          <div className="tracking_label">{t('Reward')} :</div>
          <div className="tracking_value">{name}</div>
        </div>
        <div className="tracking_row">
          <div className="tracking_label">{t('Reward date')} :</div>
          <div className="tracking_value">{rewardDate}</div>
        </div>
      </div>
      <div className="delivery_title">
        <Icons.Verify />
        <div className="txt_title">
          {t('We have received your login process', { ns: 'message' })}
        </div>
      </div>
      {shippingBrand && (
        <div className="delivery_sub_title">บริษัทขนส่ง : {shippingBrand}</div>
      )}
      {shippingPhoneNumber && (
        <div className="delivery_sub_title">
          เบอร์โทรสอบถาม : {shippingPhoneNumber}
        </div>
      )}
      {shipping_ref_no && (
        <div className="delivery_sub_title">
          {t('Your parcel number')} : {shipping_ref_no}
        </div>
      )}
      {shippingLink && (
        <div className="delivery_sub_title">
          <a href={shippingLink} rel="noreferrer" target="_blank">
            ลิงค์เช็คพัสดุ
          </a>
        </div>
      )}
      {/* <div className="deliver_status">
        <DeliveryStepWidget step={status} />
      </div> */}
      <div className="btn_back_wrap">
        <Buttons.BgStandard
          label={t('Back', { ns: 'common' })}
          onClick={onBack}
        />
      </div>
    </TrackingRewardStyled>
  );
};

TrackingReward.propTypes = {};

export default TrackingReward;
