import styled from 'styled-components';

export const HistoryContainerStyled = styled.div`
  .loading_show {
    height: calc(100vh - 199px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .card_order_wrap {
    border-radius: 16px;
    width: 100%;
    max-width: 350px;
    padding: 18px 12px;
    background: ${({ theme }) => theme.COLORS.WHITE_2};
  }
`;
