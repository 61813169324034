import styled from 'styled-components';

export const TextAreaStyled = styled.div`
  .label {
  }
  textarea {
    border: 1px solid ${({ theme }) => theme.COLORS.GRAY_4};
    border-radius: 6px;
    padding: 10px 14px;
    width: calc(100% - 30px);
    height: 23px;
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_LIGHT};
    color: ${({ theme }) => theme.COLORS.GRAY_5};
    resize: none;
  }
  .errors {
    margin-top: 2px;
    font-size: ${({ theme }) => theme.FONT.SIZE.S12};
    color: ${({ theme }) => theme.COLORS.RED_1};
  }

  .theme_standard {
  }
`;
