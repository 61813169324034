export default function renderIcon({ color = '#FF0000' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.95"
      height="23.947"
      viewBox="0 0 23.95 23.947"
    >
      <path
        id="Path_687"
        data-name="Path 687"
        d="M856.32,26.956A11.974,11.974,0,1,1,868.3,15.149,11.984,11.984,0,0,1,856.32,26.956M854.3,17.129c-.533.252-1.071.512-1.614.761a9.37,9.37,0,0,0-4.277,3.851.194.194,0,0,0,.029.279,10.373,10.373,0,0,0,9.2,3.434,10.265,10.265,0,0,0,6.748-3.644.174.174,0,0,0,.025-.245,8.962,8.962,0,0,0-3.373-3.257c-.787-.427-1.621-.768-2.433-1.148l-.192-.092c.051-.054.084-.09.119-.125.428-.433.86-.861,1.282-1.3a4.845,4.845,0,0,0,1.39-4.13A4.9,4.9,0,0,0,855.536,7.3a4.7,4.7,0,0,0-3.84,3.2,4.723,4.723,0,0,0,.937,4.919c.329.386.719.719,1.076,1.08.2.2.389.412.594.629"
        transform="translate(-844.348 -3.009)"
        fill={color}
      />
    </svg>
  );
}
