import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { DropdownSelectStyled } from './styled';
import Select from 'react-select';
import 'react-dropdown/style.css';
import theme from 'styles/theme.json';

const DropdownSelect = ({
  theme_standard,
  label,
  options,
  value,
  onChange,
  onChaneCustom,
  placeholder,
  errors,
  disabled,
  isSplitLabel,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      // borderBottom: '1px dotted pink',
      color: state.isSelected ? theme.COLORS.GRAY_5 : theme.COLORS.GRAY_5,
      padding: '8px 10px',
      background: state.isFocused ? '#f2f9fc' : theme.COLORS.WHITE_1,
    }),
    control: () => ({
      // none of react-select's styles are passed to <Control />
      width: 'calc(100% - 6px)',
      display: 'flex',
      border: `1px solid ${theme.COLORS.GRAY_4}`,
      borderRadius: 6,
      padding: '2.5px 2px 2.5px 2px',
      background: theme.COLORS.WHITE_1,
      fontSize: theme.FONT.SIZE.S14,
      fontFamily: theme.FONT.STYLE.KANIT_LIGHT,
      color: theme.COLORS.GRAY_5,
    }),
    singleValue: (provided, state) => {
      // const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return {
        ...provided,
        // opacity,
        transition,
        color: theme.COLORS.GRAY_5,
      };
    },
  };
  return (
    <DropdownSelectStyled>
      <div className={customClass}>
        {label && <div className="label"></div>}
        <Select
          styles={customStyles}
          isDisabled={disabled}
          options={options}
          onChange={(e) => {
            // onChange(isSplitLabel ? e.split(' ')[0] : e);
            onChange({
              label: isSplitLabel ? e.label.split(' ')[0] : e.label,
              value: e.value,
            });
            onChaneCustom && onChaneCustom(e);
          }}
          value={value}
          placeholder={placeholder}
        />
        {errors && <div className="errors">{errors}</div>}
      </div>
    </DropdownSelectStyled>
  );
};

DropdownSelect.propTypes = {};

export default DropdownSelect;
