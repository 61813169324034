import React from 'react';
import { withTranslation } from 'react-i18next';
import { ScanQRCodeContainerStyled } from './styled';
import { withRouter } from 'navigations/withRouter';
import { Typhographys, Forms, Displays } from 'components';
import { ROUTE_PATH } from 'utils/constants/routePath';
// import { toast } from 'react-toastify';
// import { userService } from 'apiServices';

class ScanQRCodeContainer extends React.Component {
  state = {};

  componentDidMount() { }

  handleClickChangLang = () => {
    const { i18n } = this.props;
    i18n.changeLanguage('en');
  };

  handleSubmit = (e) => {
    this.props.router.navigate(ROUTE_PATH.PRODUCT_REGISTER + '/' + e.serilal_number);
  };

  render() {
    const { t } = this.props;
    const { isLoading } = this.state;
    return (
      <ScanQRCodeContainerStyled useSuspense={false}>
        {isLoading ? (
          <div className="loading_show">
            <Displays.Loading />
          </div>
        ) : (
          <>
            <Typhographys.H1Page
              label={t('Check customer status', { ns: 'registerproduct' })}
            />
            <Typhographys.H2Page
              label={t('Scan QR Code', { ns: 'registerproduct' })}
            />
            <Forms.ScanQRCodeForm
              onSubmit={this.handleSubmit}
            />
          </>
        )}
      </ScanQRCodeContainerStyled>
    );
  }
}

export default withTranslation('registerproduct')(
  withRouter(ScanQRCodeContainer)
);
