import styled from 'styled-components';

export const NavigationBottomWidgetStyled = styled.div`
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  display: flex;
  background: ${({ theme }) => theme.COLORS.WHITE_2};
  overflow: hidden;
  .nav_item {
    flex: 1;
    padding: 8px 6px 6px 6px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    row-gap: 2px;
    cursor: pointer;
    .nav_label {
      font-size: ${({ theme }) => theme.FONT.SIZE.S12};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_LIGHT};
      color: ${({ theme }) => theme.COLORS.RED_1};
    }
    &.active {
      background: ${({ theme }) => theme.COLORS.RED_1};
      .nav_label {
        color: ${({ theme }) => theme.COLORS.WHITE_2};
      }
    }
  }
`;
