import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { H2PageStyled } from './styled';

const H2Page = ({ theme_standard, label }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <H2PageStyled>
      <div className={`h2_p_title ${customClass}`}>{label}</div>
    </H2PageStyled>
  );
};

H2Page.propTypes = {};

export default H2Page;
