import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { ProductRewardStyled } from './styled';

const ProductReward = ({ theme_standard, name, point, price, url }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <ProductRewardStyled>
      <div className={customClass}>
        <div className="product_reward_container">
          <div className="product_reward_preview">
            <img className="pr_img" src={url} alt="product" />
            <div className="point_container">{point}</div>
            <div className="price_container">{price}</div>
          </div>
          <div className="reward_name">{name}</div>
        </div>
      </div>
    </ProductRewardStyled>
  );
};

ProductReward.propTypes = {};

export default ProductReward;
