import React from 'react';
import { withTranslation } from 'react-i18next';
import { HistoryContainerStyled } from './styled';
import { userService } from 'apiServices';
import moment from 'moment';
import theme from 'styles/theme.json';
import { Tables, Modals, Displays, Cards } from 'components';

class HistoryContainer extends React.Component {
  state = { isLoading: true };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    let res = await userService.GET_MY_PRODUCT_HISTORY();
    if (res && res.status === 200) {
      this.setState({
        isLoading: false,
        data: res.data,
      });
    }
  };

  handleCloseModal = () => {
    this.setState({
      isShowModal: false,
    });
  };

  handleClickHistory = (e) => {
    this.setState({
      isShowModal: true,
      renderModal: (
        <div className="card_order_wrap">
          <Cards.OrderList isRead {...e} onBack={this.handleCloseModal} />
        </div>
      ),
    });
  };

  render() {
    const { t } = this.props;
    const { isShowModal, renderModal, isLoading, data } = this.state;
    return (
      <HistoryContainerStyled useSuspense={false}>
        {isLoading ? (
          <div className="loading_show">
            <Displays.Loading />
          </div>
        ) : (
          <>
            <Tables.HeaderRowPanel
              tableHeader={t('History', { ns: 'history' })}
              columns={columns({ t })}
              data={data}
              onClickRow={this.handleClickHistory}
            />
            <Modals.BodyEmpty
              isShowModal={isShowModal}
              onCloseModal={this.handleCloseModal}
            >\
              {renderModal}
            </Modals.BodyEmpty>
          </>
        )}
      </HistoryContainerStyled>
    );
  }
}

const columns = ({ t }) => [
  {
    title: t('Date', { ns: 'history' }),
    field: 'updated_at',
    style: { color: theme.COLORS.RED_1 },
    render: ({ value }) => moment(value).format('DD/MM/YYYY'),
  },
  {
    title: t('Serial', { ns: 'history' }),
    field: 'serial_no',
  },
  {
    title: t('Points earned', { ns: 'history' }),
    field: 'point',
    headerStyle: {
      textAlign: 'right',
    },
    style: {
      textAlign: 'right',
    },
  },
];

export default withTranslation('history')(HistoryContainer);
