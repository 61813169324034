import React from 'react';
// import PropTypes from 'prop-types'
import { RedeemRewardStyled } from './styled';
import { useTranslation } from 'react-i18next';
import { Buttons } from 'components';

const RedeemReward = ({
  imgUri,
  name,
  rewardDate,
  onBack,
  // onRedeemSuccess,
}) => {
  const { t } = useTranslation('message');

  return (
    <RedeemRewardStyled>
      <img className="r_reward_img" src={imgUri} alt="reward product" />
      <div className="redeem_container">
        <div className="redeem_row">
          <div className="redeem_label">{t('Reward')} :</div>
          <div className="redeem_value">{name}</div>
        </div>
        <div className="redeem_row">
          <div className="redeem_label">{t('Reward date')} :</div>
          <div className="redeem_value">{rewardDate}</div>
        </div>
      </div>
      <div className="redeem_action_row">
        <div className="redeem_action_col">
          <Buttons.BgStandard
            theme_cancel
            label={t('Back', { ns: 'common' })}
            onClick={onBack}
          />
        </div>
        {/* <div className="redeem_action_col">
          <Buttons.BgStandard
            label={t('Redeem rewards', { ns: 'common' })}
            onClick={onRedeemSuccess}
          />
        </div> */}
      </div>
    </RedeemRewardStyled>
  );
};

RedeemReward.propTypes = {};

export default RedeemReward;
