import styled from 'styled-components';

export const NewDetailContainerStyled = styled.div`
  .loading_show {
    height: 45vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .new_img_wrap {
    margin-bottom: 16px;
    text-align: center;
    img {
      width: 144px;
      height: 144px;
      object-fit: cover;
    }
  }
  .new_content {
    border-radius: 16px;
    padding: 12px;
    background: ${({ theme }) => theme.COLORS.WHITE_2};
    .title {
      margin-bottom: 4px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
      color: ${({ theme }) => theme.COLORS.BLACK_2};
    }
    .sub_title {
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_LIGHT};
      color: ${({ theme }) => theme.COLORS.BLACK_2};
      white-space: break-spaces;
    }
  }
`;
