import React, { useState } from 'react';
import { VerifyOTPFormStyled } from './styled';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Countdown from 'react-countdown';
import { Inputs, Buttons, Icons } from 'components';

const VerifyOTPForm = ({
  phoneNumber,
  onSubmit,
  onCancel,
  onClickResendOTP,
}) => {
  const schema = yup.object().shape({
    code: yup.string().required('Code required'),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [_resendBtnDisabled, _setResendBtnDisabled] = useState(true);

  const _onClickResendOTP = () => {
    _setResendBtnDisabled(true);
    onClickResendOTP && onClickResendOTP();
  };

  const _onComplete = () => {
    _setResendBtnDisabled(false);
  };

  const { t } = useTranslation(['form', 'common']);

  return (
    <VerifyOTPFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="v_otp_title">
          {t('Verify your identity', { ns: 'form' })}
        </div>
        <div className="v_otp_sub_title">
          {t('The verification code has been sent to', { ns: 'form' })}
          {` ${phoneNumber}`}
        </div>
        <div className="v_otp_code_row">
          <div className="cr_left_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextField
                  {...field}
                  placeholder={t('OTP code', { ns: 'form' })}
                  errors={errors.code?.message}
                />
              )}
              name="code"
              defaultValue=""
            />
          </div>
          <div className="cr_right_col">
            <Buttons.IconBgStandard
              iconPrefix={<Icons.Resend />}
              label={t('Send again', { ns: 'form' })}
              disabled={_resendBtnDisabled}
              onClick={_onClickResendOTP}
            />
          </div>
        </div>
        <div className="otp_detail">
          {_resendBtnDisabled && (
            <Countdown
              date={Date.now() + 60000}
              renderer={({ minutes, seconds }) => (
                <div>
                  0{minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                </div>
              )}
              onComplete={_onComplete}
            />
          )}
          {t('OTP code will be sent within 1 minutes', { ns: 'form' })}
        </div>
        <div className="v_otp_action_row">
          <div className="v_otp_a_col">
            <Buttons.IconBgStandard
              theme_cancel
              iconPrefix={<Icons.Cancel />}
              label={t('Cancel', { ns: 'common' })}
              onClick={onCancel}
            />
          </div>
          <div className="v_otp_a_col">
            <Buttons.IconBgStandard
              type="submit"
              iconPrefix={<Icons.Confirm />}
              label={t('Confirm', { ns: 'common' })}
            />
          </div>
        </div>
      </form>
    </VerifyOTPFormStyled>
  );
};

VerifyOTPForm.propTypes = {};

export default VerifyOTPForm;
