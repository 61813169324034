import styled from 'styled-components';

export const RegisterTypeStoreFormStyled = styled.div`
  border-radius: 16px;
  background: ${({ theme }) => theme.COLORS.WHITE_2};
  padding: 34px 32px 22px 32px;
  .input_col_wrap {
    margin-bottom: 26px;
  }
`;
