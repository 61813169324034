import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { BgStandardStyled } from './styled';

const BgStandard = ({ theme_cancel, type = 'button', label, onClick }) => {
  const customClass = cx({
    theme_cancel: theme_cancel,
  });
  return (
    <BgStandardStyled>
      <div className={customClass}>
        <button type={type} className="btn" onClick={onClick}>
          {label}
        </button>
      </div>
    </BgStandardStyled>
  );
};

BgStandard.propTypes = {};

export default BgStandard;
