import styled from 'styled-components';

export const H1PageStyled = styled.div`
  .h1_p_title {
    text-align: center;
    font-size: ${({ theme }) => theme.FONT.SIZE.S20};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
    color: ${({ theme }) => theme.COLORS.WHITE_2};
  }

  .h1_p_title {
    &.theme_red {
      color: ${({ theme }) => theme.COLORS.RED_1};
    }
  }
`;
