import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RegisterStoreInfomationContainerStyled } from './styled';
import {
  // AcceptPDPA,
  VerifyOTP,
  StoreConfirm,
  Policy,
} from './Modals';
import { withRouter } from 'navigations/withRouter';
import { ROUTE_PATH } from 'utils/constants/routePath';
import { userService } from 'apiServices';
import { toast } from 'react-toastify';
import { setReduxAuthen } from 'store/actions';
import { Forms, Modals, Typhographys, Displays } from 'components';

class RegisterStoreInfomationContainer extends React.Component {
  state = { dataRegister: null };

  componentDidMount() {}

  handleCloseModal = () => {
    this.setState({
      isShowModal: false,
    });
  };

  handleSubmit = async (values) => {
    const { storeType } = this.props;
    if (values.is_main_branch.value) {
      this.setState({
        isShowModal: true,
        dataRegister: values,
        renderModal: (
          <StoreConfirm
            onEdit={this.handleCloseModal}
            onStoreConfirm={this.handleStoreConfirm}
            data={{ ...values, storeType }}
          />
        ),
      });
    } else {
      let res = await userService.GET_RETAILER_BY_SHOP_NO(
        values.parent_shop_no
      );
      if (res && res.status === 200) {
        this.setState({
          isShowModal: true,
          dataRegister: { ...values, parent_retailer_ref_id: res.data._id },
          renderModal: (
            <StoreConfirm
              onEdit={this.handleCloseModal}
              onStoreConfirm={this.handleStoreConfirm}
              data={{ ...values, storeType }}
            />
          ),
        });
      } else {
        toast.error('รหัสสาขาหลักไม่ถูกต้อง');
      }
    }
  };

  handleStoreConfirm = () => {
    const { dataRegister } = this.state;
    this.setState({
      isShowModal: true,
      renderModal: (
        <VerifyOTP
          phoneNumber={dataRegister?.phone_number || '0623080478'}
          onCancel={this.handleCloseModal}
          onVerifySuccess={this.handleSignup}
        />
      ),
    });
  };

  // handleVerifySuccess = async () => {
  //   this.setState({
  //     isShowModal: true,
  //     renderModal: (
  //       <AcceptPDPA
  //         onCancel={this.handleCloseModal}
  //         onAcceptPDPA={this.handleAcceptPDPA}
  //       />
  //     ),
  //   });
  // };

  handleClickPolicy = (key) => {
    switch (key) {
      case 'accept_profile_info':
        this.setState({
          renderModal: <Policy handleClose={this.handleCloseModal} />,
          isShowModal: true,
        });
        break;
      case 'accept_pdpa':
        this.setState({
          renderModal: <Policy handleClose={this.handleCloseModal} />,
          isShowModal: true,
        });
        break;
      default:
        break;
    }
  };

  handleSignup = async () => {
    const { storeType, t, authenRedux } = this.props;
    const { dataRegister } = this.state;
    this.setState({
      isShowModal: false,
      isBGLoading: true,
    });
    let params = {
      address: dataRegister.address,
      firstname: dataRegister.firstname,
      lastname: dataRegister.lastname,
      tax_id: dataRegister.tax_id,
      password: dataRegister.password,
      phone_number: dataRegister.phone_number,
      store_name: dataRegister.store_name,
      username: dataRegister.username,
      zipcode: dataRegister.zipcode,
      district: dataRegister.district.value,
      is_main_branch: dataRegister.is_main_branch.value,
      province: dataRegister.province.value,
      sub_district: dataRegister.sub_district.value,
      type: storeType,
      status: '1',
      pdpa_updated_at: new Date(),
      tc_updated_at: new Date(),
    };
    if (!params.is_main_branch) {
      params.parent_retailer_ref_id = dataRegister.parent_retailer_ref_id;
    }
    let res = await userService.PUT_RETAILER_BRANCH(authenRedux._id, params);
    if (res && res.status === 200) {
      let resArrUpdate = await Promise.all([
        userService.POST_TOKEN_LOGIN(),
        userService.GET_MY_PROFILE(),
      ]);
      if (resArrUpdate) {
        localStorage.setItem('token', resArrUpdate[0].token);
        this.props.setReduxAuthen(resArrUpdate[1].data);
      }
      if (
        !dataRegister.img_path ||
        (dataRegister.img_path &&
          dataRegister.img_path.length === 0 &&
          !dataRegister.img_path[0].isNewUpload)
      ) {
        this.setState({
          isBGLoading: false,
        });
        toast.success(t('Add success', { ns: 'translation' }));
        this.props.router.navigate(ROUTE_PATH.REGISTER_SUCCESS);
      } else {
        this.handleUpload(res.data._id, dataRegister.img_path);
      }
    } else {
      this.setState({
        isBGLoading: false,
      });
      toast.error(t('Add failed', { ns: 'translation' }));
    }
  };
  handleUpload = async (id, files) => {
    const { t } = this.props;
    let bodyFormData = new FormData();
    bodyFormData.set('_id', id);
    bodyFormData.append('image', files[0]);
    let res = await userService.POST_UPLOAD_RETAILER(bodyFormData);
    if (res && res.status === 200) {
      this.setState({
        isBGLoading: false,
      });
      toast.success(t('Add success', { ns: 'translation' }));
      this.props.router.navigate(ROUTE_PATH.REGISTER_SUCCESS);
    } else {
      this.setState({
        isBGLoading: false,
      });
      toast.error('Upload fail');
      this.props.router.navigate(ROUTE_PATH.REGISTER_SUCCESS);
    }
  };

  render() {
    const { t } = this.props;
    const { isShowModal, renderModal, isLoading, isBGLoading } = this.state;
    return (
      <RegisterStoreInfomationContainerStyled useSuspense={false}>
        <Displays.BGLoading visible={isBGLoading} />
        <div className="regis_info_title">
          <Typhographys.H1Page label={t('Please register the store')} />
        </div>
        {isLoading ? (
          <div className="loading_show">
            <Displays.Loading />
          </div>
        ) : (
          <Forms.RegisterStoreInfomationForm
            onClickPolicy={this.handleClickPolicy}
            onSubmit={this.handleSubmit}
          />
        )}
        <Modals.BodyEmpty
          isShowModal={isShowModal}
          onCloseModal={this.handleCloseModal}
        >
          {renderModal}
        </Modals.BodyEmpty>
      </RegisterStoreInfomationContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxAuthen: (data) => dispatch(setReduxAuthen(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('register')(withRouter(RegisterStoreInfomationContainer)));
