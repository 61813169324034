import styled from 'styled-components';

export const ScanQRCodeContainerStyled = styled.div`
    .loading_show {
        height: calc(100vh - 199px);
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;
