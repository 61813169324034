import React from 'react';
// import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next';
import { StoreConfirmStyled } from './styled';
import { RENDER_STORE_TYPE } from 'utils/functions/store';
import { Typhographys, Buttons } from 'components';

const StoreConfirm = ({ isRead, onEdit, onStoreConfirm, data }) => {
  const { t } = useTranslation(['form', 'common']);
  return (
    <StoreConfirmStyled>
      <div className="sc_title">
        <Typhographys.H1Page
          theme_red
          label={t('Registration information', { ns: 'form' })}
        />
      </div>
      <div className="rg_txt_detail">
        {t('Store type', { ns: 'form' })} :{' '}
        {t(RENDER_STORE_TYPE(data.storeType), { ns: 'form' })}
      </div>
      <div className="rg_txt_detail">
        {`${t('Name', { ns: 'form' })}-${t('Lastname', {
          ns: 'form',
        })}`}{' '}
        : {`${data?.firstname} ${data?.lastname}`}
      </div>
      <div className="rg_txt_detail">
        {t('Phone number', { ns: 'form' })} : {data?.phone_number}
      </div>
      <div className="rg_txt_detail">
        {t('Store name', { ns: 'form' })} : {data?.store_name}
      </div>
      <div className="rg_txt_detail">
        {t('Branch type', { ns: 'form' })} : {data?.is_main_branch.label}
      </div>
      <div className="rg_txt_detail mb_16">
        {t('Address', { ns: 'form' })} : {data?.address} {' ต.'}{' '}
        {data?.sub_district.label} {'อ. '} {data?.district.label} {'จ. '}{' '}
        {data?.province.label} {data?.zipcode}
      </div>
      <div className="store_img_wrap">
        {data.img_path && data.img_path.length > 0 && (
          <img
            className="store_img"
            src={data.img_path[0].preview}
            alt="SMP QR Code Product"
          />
        )}
      </div>
      {isRead ? (
        <Buttons.BgStandard
          label={t('Back', { ns: 'common' })}
          onClick={onEdit}
        />
      ) : (
        <div className="rg_action_row">
          <div className="rg_action_col">
            <Buttons.BgStandard
              theme_cancel
              label={t('Edit', { ns: 'common' })}
              onClick={onEdit}
            />
          </div>
          <div className="rg_action_col">
            <Buttons.BgStandard
              label={t('Confirm', { ns: 'common' })}
              onClick={onStoreConfirm}
            />
          </div>
        </div>
      )}
    </StoreConfirmStyled>
  );
};

StoreConfirm.propTypes = {};

export default StoreConfirm;
