import React from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
// import PropTypes from 'prop-types'
import { RewardItemStyled } from './styled';

const RewardItem = ({
  theme_standard,
  imgUri,
  name,
  rewardDate,
  status,
  onClick,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  const { t } = useTranslation('message');

  return (
    <RewardItemStyled onClick={onClick}>
      <div className={customClass}>
        <div className="reward_item_row">
          <div className="item_left_col">
            <div className="figture_img">
              <img src={imgUri} alt="reward item" />
            </div>
          </div>
          <div className="item_right_col">
            <div className="data_row">
              <div className="dr_label">{t('Reward')} :</div>
              <div className="dr_value">{name}</div>
            </div>
            <div className="data_row">
              <div className="dr_label">{t('Reward date')} :</div>
              <div className="dr_value">{rewardDate}</div>
            </div>
            <div className="data_row">
              <div className="dr_label">{t('Status')} :</div>
              <div className="dr_value">{status}</div>
            </div>
          </div>
        </div>
      </div>
    </RewardItemStyled>
  );
};

RewardItem.propTypes = {};

export default RewardItem;
