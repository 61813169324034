import React, { useState } from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { ScanQRCodeStyled } from './styled';
import { Icons } from 'components';
import { QrReader } from 'react-qr-reader';

const ScanQRCode = ({ theme_standard, successScan }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  const [_isOpen, setIsOpen] = useState(false);

  const handleClickScan = () => {
    setIsOpen(true);
  };

  return (
    <ScanQRCodeStyled>
      <div className={customClass}>
        {!_isOpen ? (
          <div className="scan_qr_container" onClick={handleClickScan}>
            <Icons.Camera />
          </div>
        ) : (
          <div className="scan_show">
            <QrReader
              constraints={{
                facingMode: 'environment',
              }}
              onResult={(result, error) => {
                if (!!result) {
                  successScan(result?.text);
                  setIsOpen(false);
                }

                if (!!error) {
                  console.info(error);
                }
              }}
              style={{ width: '100%' }}
            />
          </div>
        )}
      </div>
    </ScanQRCodeStyled>
  );
};

ScanQRCode.propTypes = {};

export default ScanQRCode;
