import React, { useEffect, useState } from 'react';
import { RegisterStoreInfomationFormStyled } from './styled';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import address from 'utils/jsons/thailandAddress/address.json';
import { Inputs, Buttons } from 'components';

const RegisterStoreInfomationForm = ({
  initialValues,
  onSubmit,
  onClickPolicy,
}) => {
  const { t } = useTranslation(['form', 'common']);

  const schema = yup.object().shape({
    // username: yup.string().required(t('Please enter username', { ns: 'form' })),
    // password: yup.string().required(t('Please enter password', { ns: 'form' })),
    firstname: yup
      .string()
      .required(t('Please enter firstname', { ns: 'form' })),
    tax_id: yup.string().required(t('Please enter tax no', { ns: 'form' })),
    store_name: yup
      .string()
      .required(t('Please enter store name', { ns: 'form' })),
    lastname: yup.string().required(t('Please enter lastname', { ns: 'form' })),
    phone_number: yup
      .string()
      .required(t('Please enter phone number', { ns: 'form' })),
    is_main_branch: yup
      .object()
      .required(t('Please select branch', { ns: 'form' }))
      .nullable(),
    parent_shop_no: yup.string().when('is_main_branch', {
      is: (aCheckbox) => (!aCheckbox ? true : aCheckbox.value === false),
      then: yup
        .string()
        .required(t('Please enter parent shop code', { ns: 'form' })),
    }),
    address: yup.string().required(t('Please enter address', { ns: 'form' })),
    province: yup
      .object()
      .required(t('Please select province', { ns: 'form' }))
      .nullable(),
    district: yup
      .object()
      .required(t('Please select district', { ns: 'form' }))
      .nullable(),
    zipcode: yup.string().required(t('Please enter zipcode', { ns: 'form' })),
    sub_district: yup
      .object()
      .required(t('Please select sub district', { ns: 'form' }))
      .nullable(),
    is_agree_term: yup
      .bool()
      .nullable()
      .oneOf([true], t('Please accept condition', { ns: 'form' })),
    is_agree_rule: yup
      .bool()
      .nullable()
      .oneOf([true], t('Please accept condition', { ns: 'form' })),
  });

  const {
    control,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const watchIsMainBranch = watch('is_main_branch');

  const [_provinceOption, _setProvinceOption] = useState([]);
  const [_districtOption, _setDistrictOption] = useState([]);
  const [_subdistrictOption, _setSubdistrictOption] = useState([]);

  useEffect(() => {
    _setProvinceOption(
      address.reduce((result, data, i) => {
        if (i === 0) {
          result.push({ label: data.province, value: data.province });
        } else {
          let find = result.find((e) => e.value === data.province);
          if (!find) {
            result.push({ label: data.province, value: data.province });
          }
        }
        return result;
      }, [])
    );
  }, []);

  const _handleChangeProvince = (e) => {
    setValue('district', '');
    setValue('sub_district', '');
    setValue('zipcode', '');
    _setDistrictOption(
      address.reduce((result, data, i) => {
        if (data.province === e.value) {
          // let find = result.find((e) => e.value === data.amphoe);
          // if (!find) {
          result.push({
            label: `${data.amphoe} ${data.district} ${data.zipcode}`,
            value: data.amphoe,
          });
          // }
        }
        return result;
      }, [])
    );
  };

  const _handleChangeDistrict = (e) => {
    let splitData = e.label.split(' ');
    setValue('sub_district', { label: splitData[1], value: splitData[1] });
    setValue('zipcode', splitData[2]);
    _setSubdistrictOption(
      address.reduce((result, data, i) => {
        if (data.amphoe === e.value) {
          let find = result.find((e) => e.value === data.district);
          if (!find) {
            result.push({
              label: data.district,
              value: data.district,
              zipcode: data.zipcode,
            });
          }
        }
        return result;
      }, [])
    );
  };

  const _handleChangeSubDistrict = (e) => {
    let find = _subdistrictOption.find((f) => f.value === e.value);
    if (find) {
      setValue('zipcode', find.zipcode);
    }
  };

  return (
    <RegisterStoreInfomationFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* <div className="input_col_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                placeholder={t('Username', { ns: 'form' })}
                errors={errors.username?.message}
              />
            )}
            name="username"
            defaultValue=""
          />
        </div>
        <div className="input_col_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                placeholder={t('Password', { ns: 'form' })}
                errors={errors.password?.message}
                type="password"
              />
            )}
            name="password"
            defaultValue=""
          />
        </div> */}
        <div className="input_col_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                placeholder={t('Name', { ns: 'form' })}
                errors={errors.firstname?.message}
              />
            )}
            name="firstname"
            defaultValue=""
          />
        </div>
        <div className="input_col_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                placeholder={t('Lastname', { ns: 'form' })}
                errors={errors.lastname?.message}
              />
            )}
            name="lastname"
            defaultValue=""
          />
        </div>
        <div className="input_col_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                placeholder={t('Tax no', { ns: 'form' })}
                errors={errors.tax_id?.message}
              />
            )}
            name="tax_id"
            defaultValue=""
          />
        </div>
        <div className="input_col_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                type="tel"
                placeholder={t('Phone number', { ns: 'form' })}
                errors={errors.phone_number?.message}
              />
            )}
            name="phone_number"
            defaultValue=""
          />
        </div>
        <div className="input_col_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                placeholder={t('Store name', { ns: 'form' })}
                errors={errors.store_name?.message}
              />
            )}
            name="store_name"
            defaultValue=""
          />
        </div>
        <div className="input_col_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.DropdownSelect
                {...field}
                options={[
                  {
                    label: t('Main branch', { ns: 'form' }),
                    value: true,
                  },
                  {
                    label: t('Branch', { ns: 'form' }),
                    value: false,
                  },
                ]}
                placeholder={t('Branchs', { ns: 'form' })}
                errors={errors.is_main_branch?.message}
              />
            )}
            name="is_main_branch"
            defaultValue=""
          />
        </div>
        {watchIsMainBranch && watchIsMainBranch.value === false && (
          <div className="input_col_wrap">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextField
                  {...field}
                  placeholder={t('Shop main code', { ns: 'form' })}
                  errors={errors.parent_shop_no?.message}
                />
              )}
              name="parent_shop_no"
              defaultValue=""
            />
          </div>
        )}
        <div className="input_col_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                placeholder={t('AddressEtc', { ns: 'form' })}
                errors={errors.address?.message}
              />
            )}
            name="address"
            defaultValue=""
          />
        </div>
        <div className="input_col_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.DropdownSelect
                {...field}
                options={_provinceOption}
                placeholder={t('Province', { ns: 'form' })}
                errors={errors.province?.message}
                onChaneCustom={_handleChangeProvince}
              />
            )}
            name="province"
            defaultValue=""
          />
        </div>
        <div className="input_col_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.DropdownSelect
                {...field}
                isSplitLabel
                options={_districtOption}
                placeholder={t('District', { ns: 'form' })}
                errors={errors.district?.message}
                onChaneCustom={_handleChangeDistrict}
              />
            )}
            name="district"
            defaultValue=""
          />
        </div>
        <div className="input_col_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.DropdownSelect
                {...field}
                disabled
                options={_subdistrictOption}
                placeholder={t('Sub district', { ns: 'form' })}
                errors={errors.sub_district?.message}
                onChaneCustom={_handleChangeSubDistrict}
              />
            )}
            name="sub_district"
            defaultValue=""
          />
        </div>
        <div className="input_col_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                disabled
                placeholder={t('Zip code', { ns: 'form' })}
                errors={errors.zipcode?.message}
              />
            )}
            name="zipcode"
            defaultValue=""
          />
        </div>
        <div className="input_col_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.Upload
                {...field}
                placeholder={t('Upload a photo of the store', { ns: 'form' })}
                // errors={errors.phone?.message}
              />
            )}
            name="img_path"
            defaultValue=""
          />
        </div>
        <div className="input_col_wrap flex mb_8 pl_16">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.Checkbox
                {...field}
                placeholder={
                  <>
                    {t('Accept the terms and conditions - Privacy Policy', {
                      ns: 'form',
                    })}
                    <span
                      className="click_more"
                      onClick={() => onClickPolicy('accept_profile_info')}
                    >
                      คลิก
                    </span>
                  </>
                }
                errors={errors.is_agree_term?.message}
              />
            )}
            name="is_agree_term"
            defaultValue={false}
          />
        </div>
        <div className="input_col_wrap flex mb_22 pl_16">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.Checkbox
                {...field}
                placeholder={
                  <>
                    {t(
                      'Accept the terms and conditions of participation in the activity',
                      { ns: 'form' }
                    )}
                    <span
                      className="click_more"
                      onClick={() => onClickPolicy('accept_pdpa')}
                    >
                      คลิก
                    </span>
                  </>
                }
                errors={errors.is_agree_rule?.message}
              />
            )}
            name="is_agree_rule"
            defaultValue={false}
          />
        </div>
        <Buttons.BgStandard type="submit" label={t('OK', { ns: 'common' })} />
      </form>
    </RegisterStoreInfomationFormStyled>
  );
};

RegisterStoreInfomationForm.propTypes = {};

export default RegisterStoreInfomationForm;
