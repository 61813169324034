import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { OrderListStyled } from './styled';
import { Buttons } from 'components';

const OrderList = ({
  theme_standard,
  isRead,
  firstname,
  lastname,
  serial_no,
  car_model,
  car_year,
  product_type,
  install_position,
  product_model,
  car_plate,
  retailer_ref_id,
  car_brand,
  onBack,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <OrderListStyled>
      <div className={customClass}>
        <div className="ol_container">
          {firstname && (
            <div className="rp_detail_txt">
              ชื่อลูกค้า : {`${firstname} ${lastname}`}
            </div>
          )}
          <div className="rp_detail_txt">เลขซีเรียล : {serial_no}</div>
          <div className="rp_detail_txt">ยี่ห้อรถ : {car_brand}</div>
          <div className="rp_detail_txt">รุ่น : {car_model}</div>
          <div className="rp_detail_txt">ปี : {car_year}</div>
          <div className="rp_detail_txt">ประเภทโช้คอัพ : {product_type}</div>
          <div className="rp_detail_txt">
            ตำแหน่งโช้คอัพ : {install_position}
          </div>
          <div className="rp_detail_txt">รหัสสินค้า : {product_model}</div>
          <div className="rp_detail_txt">ทะเบียนรถ : {car_plate}</div>
          <div className="rp_detail_txt red">
            สถานะลูกค้า :{' '}
            {retailer_ref_id ? 'ลงทะเบียนสำเร็จ' : 'รอร้านค้าอนุมัติ'}
          </div>
          {isRead && <Buttons.BgStandard label="กลับ" onClick={onBack} />}
        </div>
      </div>
    </OrderListStyled>
  );
};

OrderList.propTypes = {};

export default OrderList;
