import styled from 'styled-components';

export const AvatarUploadStyled = styled.div`
  margin: auto;
  width: fit-content;
  .dropzone {
    position: relative;
    .avartar {
      border-radius: 112px;
      width: 112px;
      height: 112px;
      background: ${({ theme }) => theme.COLORS.WHITE_2};
      object-fit: cover;
    }
    .av_edit {
      position: absolute;
      right: -10px;
      bottom: 0px;
    }
  }

  .theme_standard {
  }
`;
