export default function renderIcon({ color = '#808080' }) {
  return (
    <svg
      id="Group_157"
      data-name="Group 157"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="62.958"
      height="50.418"
      viewBox="0 0 62.958 50.418"
    >
      <defs>
        <clipPath id="clipPath">
          <rect
            id="Rectangle_176"
            data-name="Rectangle 176"
            width="62.958"
            height="50.418"
            fill="none"
          />
        </clipPath>
      </defs>
      <g
        id="Group_156"
        data-name="Group 156"
        transform="translate(0 0)"
        clipPath="url(#clipPath)"
      >
        <path
          id="Path_677"
          data-name="Path 677"
          d="M31.474,50.417q-12.516,0-25.033,0A6.375,6.375,0,0,1,.056,44.905a6.824,6.824,0,0,1-.053-1Q0,29.93,0,15.953A6.36,6.36,0,0,1,6.534,9.412q3.546,0,7.092,0A2.653,2.653,0,0,0,16.37,7.422q.9-2.708,1.808-5.416a2.841,2.841,0,0,1,2.771-2Q31.483,0,42.017,0a2.848,2.848,0,0,1,2.766,2.013Q45.7,4.743,46.6,7.472a2.643,2.643,0,0,0,2.7,1.939q3.567,0,7.134,0a6.356,6.356,0,0,1,6.522,6.509q.008,13.976,0,27.953a6.361,6.361,0,0,1-6.533,6.543q-12.475,0-24.949,0M47.032,28.375a15.555,15.555,0,1,0-15.71,15.53,15.586,15.586,0,0,0,15.71-15.53m7.327-8.319A2.065,2.065,0,1,0,52.3,17.979a2.06,2.06,0,0,0,2.055,2.077"
          transform="translate(0 0)"
          fill={color}
        />
        <path
          id="Path_678"
          data-name="Path 678"
          d="M51.78,53.889a9.525,9.525,0,1,1,9.458,9.549,9.536,9.536,0,0,1-9.458-9.549"
          transform="translate(-29.827 -25.557)"
          fill={color}
        />
      </g>
    </svg>
  );
}
