import React from 'react';
import { ScanQRCodeFormStyled } from './styled';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Inputs, Buttons } from 'components';

const ScanQRCodeForm = ({ onSubmit }) => {
  const { t } = useTranslation(['form', 'common']);

  const schema = yup.object().shape({
    serilal_number: yup.string().required(t('Please enter serilal number', { ns: 'form' })),
  });

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const successScan = (e) => {
    setValue('serilal_number', e)
  }

  return (
    <ScanQRCodeFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          render={({ field }) => (
            <Inputs.ScanQRCode
              {...field}
              // placeholder={t('test')}
              // errors={errors.phone?.message}
              successScan={successScan}
            />
          )}
          name="test"
          defaultValue=""
        />
        <div className="txt_between_input">{t('OR', { ns: 'common' })}</div>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                placeholder={t('Enter Serial Number', { ns: 'form' })}
                errors={errors.serilal_number?.message}
              />
            )}
            name="serilal_number"
            defaultValue=""
          />
        </div>
        <Buttons.BgStandard type="submit" label={t('OK', { ns: 'common' })} />
      </form>
    </ScanQRCodeFormStyled>
  );
};

ScanQRCodeForm.propTypes = {};

export default ScanQRCodeForm;
