import { useEffect, useState } from 'react';
import { NavigationBottomWidgetStyled } from './styled';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import theme from 'styles/theme.json';
import { Icons } from 'components';
import { ROUTE_PATH } from 'utils/constants/routePath';

const NavigationBottomWidget = () => {
  let navigate = useNavigate();
  let location = useLocation();

  const { t } = useTranslation(['navigation']);

  const [_active, _setActive] = useState();

  useEffect(() => {
    if (location) {
      const splitPathname = location.pathname.split('/');
      _setActive(splitPathname[2]);
    }
  }, [location]);

  const _handleClickMenu = (e) => {
    navigate(e.routePath);
  };

  return (
    <NavigationBottomWidgetStyled>
      {menuList &&
        menuList.map((e, i) => (
          <div
            key={i}
            className={`nav_item ${_active === e.parentMenu ? 'active' : ''}`}
            onClick={() => _handleClickMenu(e)}
          >
            {_active === e.parentMenu ? e.iconAct : e.icon}
            <div className="nav_label ">{t(e.label)}</div>
          </div>
        ))}
    </NavigationBottomWidgetStyled>
  );
};

const menuList = [
  {
    parentMenu: 'register',
    label: 'Register',
    icon: <Icons.MenuProductRegis />,
    iconAct: <Icons.MenuProductRegis color={theme.COLORS.WHITE_2} />,
    routePath: ROUTE_PATH.PRODUCT_SCAN_QR_CODE,
  },
  {
    parentMenu: 'history',
    label: 'History',
    icon: <Icons.MenuHistory />,
    iconAct: <Icons.MenuHistory color={theme.COLORS.WHITE_2} />,
    routePath: ROUTE_PATH.HISTORY,
  },
  {
    parentMenu: 'redeemreward',
    label: 'Redeem',
    icon: <Icons.MenuMyReward />,
    iconAct: <Icons.MenuMyReward color={theme.COLORS.WHITE_2} />,
    routePath: ROUTE_PATH.REDEEM_REWARD,
  },
  // {
  //   parentMenu: 'message',
  //   label: 'Message',
  //   icon: <Icons.MenuNew />,
  //   iconAct: <Icons.MenuNew color={theme.COLORS.WHITE_2} />,
  //   routePath: ROUTE_PATH.MESSAGE,
  // },
  {
    parentMenu: 'news',
    label: 'New',
    icon: <Icons.MenuNew />,
    iconAct: <Icons.MenuNew color={theme.COLORS.WHITE_2} />,
    routePath: ROUTE_PATH.NEWS,
  },
  {
    parentMenu: 'profile',
    label: 'Profile',
    icon: <Icons.MenuProfile />,
    iconAct: <Icons.MenuProfile color={theme.COLORS.WHITE_2} />,
    routePath: ROUTE_PATH.PROFILE,
  },
];

export default NavigationBottomWidget;
