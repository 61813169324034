import common from './common.json';
import form from './form.json';
import history from './history.json';
import navigation from './navigation.json';
import message from './message.json';
import redeem from './redeem.json';
import register from './register.json';
import registerproduct from './registerproduct.json';
import reward from './reward.json';
import translation from './translation.json';

const Export = {
  common,
  form,
  history,
  navigation,
  redeem,
  message,
  register,
  registerproduct,
  reward,
  translation,
};

export default Export;
