import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { DropdownStyled } from './styled';
import ReactDropdown from 'react-dropdown';
import 'react-dropdown/style.css';

const Dropdown = ({
  theme_standard,
  label,
  options,
  value,
  onChange,
  onChaneCustom,
  placeholder,
  errors,
  disabled,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <DropdownStyled>
      <div className={customClass}>
        {label && <div className="label"></div>}
        <ReactDropdown
          controlClassName="input_dropdown"
          arrowClassName="arrow_dropdown"
          disabled={disabled}
          options={options}
          onChange={(e) => {
            onChange(e);
            onChaneCustom && onChaneCustom(e);
          }}
          value={value}
          placeholder={placeholder}
        />
        {errors && <div className="errors">{errors}</div>}
      </div>
    </DropdownStyled>
  );
};

Dropdown.propTypes = {};

export default Dropdown;
