export const RENDER_STORE_TYPE = (key) => {
  switch (Number(key)) {
    case 1:
      return 'Spare parts shop';
    case 2:
      return 'Installation service center';
    case 3:
      return 'Parts shop ready to install';
    default:
      break;
  }
};
