import styled from 'styled-components';

export const CounponContainerStyled = styled.div`
  .no_empty {
    margin-top: 100px;
    height: 200px;
    text-align: center;
  }
  .product_item_wrap {
  }
  .line_between_item {
    margin-top: 16px;
    margin-bottom: 16px;
    height: 3px;
    background: ${({ theme }) => theme.COLORS.RED_3};
  }
`;
