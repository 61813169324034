import React from 'react';
import { RegisterTypeStoreFormStyled } from './styled';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Inputs, Buttons } from 'components';

const RegisterTypeStoreForm = ({ onSubmit }) => {
  const { t } = useTranslation(['form', 'common']);

  const schema = yup.object().shape({
    storeType: yup
      .string()
      .nullable()
      .required(t('Please enter store type', { ns: 'form' })),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <RegisterTypeStoreFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="input_col_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.Radio
                {...field}
                options={[
                  {
                    label: t('Spare parts shop', { ns: 'form' }),
                    value: 1,
                  },
                  {
                    label: t('Installation service center', { ns: 'form' }),
                    value: 2,
                  },
                  {
                    label: t('Parts shop ready to install', { ns: 'form' }),
                    value: 3,
                  },
                ]}
                // placeholder={t('test')}
                errors={errors.store_type?.message}
              />
            )}
            name="storeType"
            defaultValue=""
          />
        </div>

        <Buttons.BgStandard type="submit" label={t('OK', { ns: 'common' })} />
      </form>
    </RegisterTypeStoreFormStyled>
  );
};

RegisterTypeStoreForm.propTypes = {};

export default RegisterTypeStoreForm;
