import styled from 'styled-components';

export const OrderListStyled = styled.div`
  .ol_container {
    .rp_detail_txt {
      margin-bottom: 4px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      color: ${({ theme }) => theme.COLORS.BLACK_2};
      &.red {
        color: ${({ theme }) => theme.COLORS.RED_1};
      }
    }
  }

  .theme_standard {
  }
`;
