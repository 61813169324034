export default function renderIcon({ color = '#FF0000' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24.376"
      height="24.525"
      viewBox="0 0 24.376 24.525"
    >
      <g
        id="Group_482"
        data-name="Group 482"
        transform="translate(-280.876 -816.972)"
      >
        <path
          id="Path_936"
          data-name="Path 936"
          d="M281.309,19.33h0Z"
          transform="translate(20.521 810)"
          fill={color}
        />
        <path
          id="Path_920"
          data-name="Path 920"
          d="M275.271,11.725h-5.152l-1.514,1.521h8.309Z"
          transform="translate(20.521 810)"
          fill={color}
        />
        <path
          id="Path_921"
          data-name="Path 921"
          d="M264.958,16.908l-1.405,1.411L265.42,19.8l-.462-.424Z"
          transform="translate(20.521 810)"
          fill={color}
        />
        <path
          id="Path_922"
          data-name="Path 922"
          d="M281.907,17.869l-1.358-1.257v2.336Z"
          transform="translate(20.521 810)"
          fill={color}
        />
        <path
          id="Path_923"
          data-name="Path 923"
          d="M280.549,13.246v.775l1.521,1.408V12.485a.761.761,0,0,0-.761-.76h-3.24l1.643,1.521Z"
          transform="translate(20.521 810)"
          fill={color}
        />
        <path
          id="Path_924"
          data-name="Path 924"
          d="M264.958,13.246h.963l1.515-1.521H264.2a.761.761,0,0,0-.761.76v3.256l1.521-1.527Z"
          transform="translate(20.521 810)"
          fill={color}
        />
        <path
          id="Path_925"
          data-name="Path 925"
          d="M267.24,16.288a.761.761,0,0,0,.761.761h8.745a.761.761,0,0,0,0-1.522H268a.761.761,0,0,0-.761.761"
          transform="translate(20.521 810)"
          fill={color}
        />
        <path
          id="Path_926"
          data-name="Path 926"
          d="M272.183,18.949H268a.761.761,0,0,0,0,1.521h4.183a.761.761,0,0,0,0-1.521"
          transform="translate(20.521 810)"
          fill={color}
        />
        <path
          id="Path_927"
          data-name="Path 927"
          d="M283.781,16.667a.928.928,0,0,0-.313.056l-1.4-1.295v3.9a.763.763,0,0,1-.273.585l-5.935,4.943a.767.767,0,0,1-.487.176h-.024l-6.422-.191a.755.755,0,0,1-.492-.2l-4.754-4.373a.761.761,0,0,1-.244-.56V15.74l-1.245,1.25a.993.993,0,0,0-.071.087.945.945,0,0,0-1.7.316.961.961,0,0,0-.029.63V30.547a.246.246,0,0,0,0,.027c0,.035.006.07.01.105s.007.055.013.082.018.061.03.092a.778.778,0,0,0,.03.085c.012.027.028.052.042.077s.034.059.053.086c.006.008.009.017.015.025s.024.021.033.033a1.1,1.1,0,0,0,.1.1c.021.017.039.033.06.049a1.07,1.07,0,0,0,.11.07c.021.011.041.024.063.034a.914.914,0,0,0,.142.049.442.442,0,0,0,.048.015.918.918,0,0,0,.2.023h22.435a.95.95,0,0,0,.95-.95V17.618a.95.95,0,0,0-.95-.951M262.3,19.748l5.681,4.511-5.681,4.36Zm1.85,9.848,5.7-4.373h5.206l5.7,4.373Zm18.685-.8-5.914-4.538,5.914-4.7Z"
          transform="translate(20.521 810)"
          fill={color}
        />
        <path
          id="Path_915"
          data-name="Path 915"
          d="M272.59,9.243l2.681,2.482h2.8l-4.86-4.5a.949.949,0,0,0-1.319.027l-4.454,4.473h2.683Z"
          transform="translate(20.521 810)"
          fill={color}
        />
        <path
          id="Path_928"
          data-name="Path 928"
          d="M263.683,20.27l4.753,4.373a.759.759,0,0,0,.493.2l6.422.191h.022a.76.76,0,0,0,.487-.176l5.936-4.943a.761.761,0,0,0,.274-.585v-3.9l-1.521-1.408v2.591l1.358,1.257-1.358,1.079v.026L275.108,23.5l-5.851-.173L265.42,19.8l-1.866-1.482,1.405-1.411V14.214l-1.521,1.527V19.71a.762.762,0,0,0,.245.56"
          transform="translate(20.521 810)"
          fill={color}
        />
        <path
          id="Path_929"
          data-name="Path 929"
          d="M268.6,13.246l1.514-1.521h-2.682l-1.515,1.521Z"
          transform="translate(20.521 810)"
          fill={color}
        />
        <path
          id="Path_930"
          data-name="Path 930"
          d="M276.914,13.246h2.8l-1.643-1.521h-2.8Z"
          transform="translate(20.521 810)"
          fill={color}
        />
        <path
          id="Path_931"
          data-name="Path 931"
          d="M269.853,23.322,265.42,19.8l3.837,3.531,5.85.173,5.442-4.531v-.026l-5.508,4.375Z"
          transform="translate(20.521 810)"
          fill={color}
        />
      </g>
    </svg>
  );
}
