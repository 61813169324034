import { useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { ROUTE_PATH } from 'utils/constants/routePath';
import { userService } from 'apiServices';
import memberRoutes from './memberRoutes';
import { Mainlayouts } from 'components';
import SplashContainer from 'containers/SplashContainer';
import LoginContainer from 'containers/LoginContainer';
import RegisterContainer from 'containers/RegisterSystem/RegisterContainer';
import RegisterSuccessContainer from 'containers/RegisterSystem/RegisterSuccessContainer';

const Navigations = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    checkToken();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const checkToken = async () => {
    let res = await userService.POST_CHECK_TOKEN();
    if (res && res.status === 200) {
      const locationSplit = location.pathname.split('/');
      switch (locationSplit[locationSplit.length - 1]) {
        case 'register':
          navigate(ROUTE_PATH.SPLASH);
          break;
        default:
          break;
      }
    } else {
      const locationSplit = location.pathname.split('/');
      switch (locationSplit[locationSplit.length - 1]) {
        case 'logins':
          navigate(ROUTE_PATH.LOGIN);
          break;
        default:
          navigate(ROUTE_PATH.SPLASH);
          break;
      }
    }
  };

  return (
    <Routes>
      <Route element={<Mainlayouts.PageLayout />}>
        <Route path={ROUTE_PATH.LOGIN} element={<LoginContainer />} />
        <Route index path={ROUTE_PATH.SPLASH} element={<SplashContainer />} />
        <Route
          path={ROUTE_PATH.REGISTER_SUCCESS}
          element={<RegisterSuccessContainer />}
        />
        <Route path={ROUTE_PATH.REGISTER} element={<RegisterContainer />} />
        <Route path="*" element={<SplashContainer />} />
      </Route>
      <Route
        path={ROUTE_PATH.SYSTEM}
        element={<Mainlayouts.NavigationBottom />}
      >
        {memberRoutes &&
          memberRoutes.map((e, i) => {
            return !e.children ? (
              <Route
                key={i}
                index={e.index}
                path={e.path}
                element={e.element}
              />
            ) : (
              <Route key={i}>
                {e.children &&
                  e.children.map((f, j) => (
                    <Route
                      key={j}
                      index={f.index}
                      path={f.path}
                      element={f.element}
                    />
                  ))}
              </Route>
            );
          })}
      </Route>
    </Routes>
  );
};

export default Navigations;
