import styled from 'styled-components';

export const NewContainerStyled = styled.div`
  .title_wrap {
    margin-bottom: 12px;
  }
  .loading_show {
    height: 45vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .banner_wrap {
    margin-bottom: 40px;
    .banner_img_wrap {
      .banner_img {
        width: 100%;
        height: auto;
        cursor: pointer;
      }
    }
  }
  .product_item_wrap {
    margin-bottom: 8px;
  }
`;
