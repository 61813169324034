export default function renderIcon({ color = 'red' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsX  link="http://www.w3.org/1999/xlink"
      width="42"
      height="42"
      viewBox="0 0 42 42"
    >
      <defs>
        <clipPath id="clipPath">
          <rect
            id="Rectangle_311"
            data-name="Rectangle 311"
            width="42"
            height="42"
            transform="translate(-0.233 -0.103)"
            fill="none"
          />
        </clipPath>
      </defs>
      <g
        id="Group_353"
        data-name="Group 353"
        transform="translate(0.233 0.103)"
      >
        <g id="Group_339" data-name="Group 339" clipPath="url(#clipPath)">
          <path
            id="Path_844"
            data-name="Path 844"
            d="M42.1,21.05A21.05,21.05,0,1,1,21.05,0,21.05,21.05,0,0,1,42.1,21.05"
            fill={color}
          />
          <path
            id="Path_845"
            data-name="Path 845"
            d="M31.9,44.234q-4.731,0-9.461,0a2.41,2.41,0,0,1-2.414-2.083,2.59,2.59,0,0,1-.02-.377q0-5.283,0-10.565a2.4,2.4,0,0,1,2.469-2.472H25.15a1,1,0,0,0,1.037-.752q.34-1.024.683-2.047a1.073,1.073,0,0,1,1.047-.757q3.981,0,7.963,0a1.077,1.077,0,0,1,1.046.761q.345,1.03.687,2.062a1,1,0,0,0,1.019.733h2.7a2.4,2.4,0,0,1,2.465,2.46q0,5.283,0,10.565a2.4,2.4,0,0,1-2.469,2.473H31.9m5.88-8.331a5.879,5.879,0,1,0-5.938,5.87,5.891,5.891,0,0,0,5.938-5.87m2.769-3.144a.78.78,0,1,0-.777-.785.778.778,0,0,0,.777.785"
            transform="translate(-10.848 -13.657)"
            fill="#fff"
          />
          <path
            id="Path_846"
            data-name="Path 846"
            d="M38.132,44.314a3.6,3.6,0,1,1,3.575,3.609,3.6,3.6,0,0,1-3.575-3.609"
            transform="translate(-20.682 -22.083)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
}
