import styled from 'styled-components';

export const RedeemRewardStyled = styled.div`
  border-radius: 16px;
  width: 100%;
  max-width: 350px;
  padding: 18px 12px;
  background: ${({ theme }) => theme.COLORS.WHITE_2};
  .r_reward_img {
    margin-bottom: 16px;
    width: 100%;
    height: auto;
  }
  .redeem_container {
    margin: auto;
    margin-bottom: 32px;
    width: fit-content;
    .redeem_row {
      display: flex;
      align-items: baseline;
      .redeem_label {
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
        color: ${({ theme }) => theme.COLORS.BLACK_2};
      }
      .redeem_value {
        padding-left: 4px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_LIGHT};
        color: ${({ theme }) => theme.COLORS.RED_1};
      }
    }
  }
  .redeem_action_row {
    display: flex;
    column-gap: 16px;
    .redeem_action_col {
      flex: 1;
    }
  }
`;
