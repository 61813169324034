import { ROUTE_PATH } from 'utils/constants/routePath';
import ScanQRCodeContainer from 'containers/RegisProductSystem/ScanQRCodeContainer';
import RegisProductContainer from 'containers/RegisProductSystem/RegisProductContainer';
import HistoryContainer from 'containers/HistoryContainer';
import RedeemRewardContainer from 'containers/RedeemRewardContainer';
import RewardContainer from 'containers/RewardContainer';
import MessageContainer from 'containers/MessageContainer';
import NewContainer from 'containers/NewSystem/NewContainer';
import NewDetailContainer from 'containers/NewSystem/NewDetailContainer';
import ProfileContainer from 'containers/ProfileContainer';

const cmsRoutes = [
  {
    key: 'register',
    name: 'register',
    children: [
      {
        key: 'scanqrcode',
        name: 'scanqrcode',
        path: ROUTE_PATH.PRODUCT_SCAN_QR_CODE,
        element: <ScanQRCodeContainer />,
      },
      {
        key: 'regisproduct',
        name: 'regisproduct',
        path: ROUTE_PATH.PRODUCT_REGISTER_DETAIL,
        element: <RegisProductContainer />,
      },
      {
        key: 'reward',
        name: 'reward',
        path: ROUTE_PATH.REWARD,
        element: <RewardContainer />,
      },
    ],
  },
  {
    key: 'redeemreward',
    name: 'redeemreward',
    path: ROUTE_PATH.REDEEM_REWARD,
    element: <RedeemRewardContainer />,
  },
  {
    key: 'history',
    name: 'history',
    path: ROUTE_PATH.HISTORY,
    element: <HistoryContainer />,
  },
  {
    key: 'myreward',
    name: 'myreward',
    path: ROUTE_PATH.MESSAGE,
    element: <MessageContainer />,
  },
  {
    key: 'new',
    name: 'new',
    path: ROUTE_PATH.NEWS,
    element: <NewContainer />,
  },
  {
    key: 'news_detail',
    name: 'news detail',
    path: ROUTE_PATH.NEWS_DETAIL,
    element: <NewDetailContainer />,
  },
  {
    key: 'profile',
    name: 'profile',
    path: ROUTE_PATH.PROFILE,
    element: <ProfileContainer />,
  },
];

export default cmsRoutes;
