import React from 'react';
// import PropTypes from 'prop-types'
import { RewardCodeScanStyled } from './styled';
import { useTranslation } from 'react-i18next';
import Countdown from 'react-countdown';
import Barcode from 'react-barcode';
import { Buttons } from 'components';

const RewardCodeScan = ({
  barcode_no,
  reward_name,
  reward_price,
  timeBalance,
  onBack,
  _onTimeoutBarcode,
}) => {
  const { t } = useTranslation(['reward', 'common']);
  return (
    <RewardCodeScanStyled>
      <div className="cs_header_sec">
        <div className="cs_product_tile">{reward_name}</div>
        <div className="cs_product_sub_title">
          {t('Discount coupon worth baht', {
            ns: 'reward',
            discount: reward_price,
          })}
        </div>
      </div>
      <div className="cs_content_sec">
        <div className="sdc_title_symbol">{t('Barcode', { ns: 'reward' })}</div>
        {barcode_no && (
          <div className="barcode_wrap">
            <Barcode value={barcode_no} width={2} />
          </div>
        )}
        <div className="cs_label">
          {t('Show store code to receive rewards', { ns: 'reward' })}
        </div>
        <div className="cs_label">
          {t('Time out in another', { ns: 'reward' })} :
          <span className="highlight">
            <Countdown
              date={
                !timeBalance ? Date.now() + 60000 * 5 : Date.now() + timeBalance
              }
              renderer={({ minutes, seconds }) => (
                <div>
                  0{minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                </div>
              )}
              onComplete={_onTimeoutBarcode}
            />
          </span>
        </div>
      </div>
      <Buttons.BgStandard
        label={t('Back', { ns: 'common' })}
        onClick={onBack}
      />
    </RewardCodeScanStyled>
  );
};

RewardCodeScan.propTypes = {};

export default RewardCodeScan;
