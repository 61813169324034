import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { H1PageStyled } from './styled';

const H1Page = ({ theme_red, label }) => {
  const customClass = cx({
    theme_red: theme_red,
  });
  return (
    <H1PageStyled>
      <div className={`h1_p_title ${customClass}`}>{label}</div>
    </H1PageStyled>
  );
};

H1Page.propTypes = {};

export default H1Page;
