import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RegisterSuccessContainerStyled } from './styled';
import { withRouter } from 'navigations/withRouter';
import { ROUTE_PATH } from 'utils/constants/routePath';
import { Typhographys, Buttons } from 'components';

class RegisterSuccessContainer extends React.Component {
  state = {};

  componentDidMount() {}

  handleClickNext = () => {
    this.props.router.navigate(ROUTE_PATH.PRODUCT_SCAN_QR_CODE);
  };

  render() {
    const { t, authenRedux } = this.props;
    return (
      <RegisterSuccessContainerStyled useSuspense={false}>
        <div className="regis_success_title">
          <Typhographys.H1Page
            label={t('Store registration success', { ns: 'register' })}
          />
        </div>
        <div className="regis_success_content">
          <div className="title_parent">
            <div>
              <div className="rsc_title">
                {t('Shop', { ns: 'register' })} : {authenRedux?.store_name}
              </div>
              <div className="rsc_title">
                {t('Branch Code', { ns: 'register' })} : {authenRedux?.shop_no}
              </div>
            </div>
          </div>

          <div className="rsc_detail">{t('Ask more', { ns: 'register' })}</div>
          <div className="rsc_detail mt_5 mb_16">02-726-8080</div>
          <Buttons.BgStandard
            label={t('OK', { ns: 'common' })}
            onClick={this.handleClickNext}
          />
        </div>
      </RegisterSuccessContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withTranslation(['common', 'register'])(withRouter(RegisterSuccessContainer))
);
