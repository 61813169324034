import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { LogoHeaderStyled } from './styled';

const LogoHeader = ({ theme_standard }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <LogoHeaderStyled>
      <div className={customClass}>
        <div className="logo_header_container">
          <div className="logo_header_wrap">
            <img
              className="logo_header_img"
              src="/assets/images/logos/logo.png"
              alt="SMP Logo"
            />
          </div>
          <div className="logo_bottom_line" />
        </div>
      </div>
    </LogoHeaderStyled>
  );
};

LogoHeader.propTypes = {};

export default LogoHeader;
