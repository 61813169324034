import styled from 'styled-components';

export const RegisterSuccessContainerStyled = styled.div`
  .regis_success_title {
    margin-bottom: 16px;
  }
  .regis_success_content {
    border-radius: 16px;
    background: ${({ theme }) => theme.COLORS.WHITE_2};
    padding: 34px 32px 22px 32px;
    .title_parent {
      display: flex;
      flex-direction: column;
      align-items: center;
      .rsc_title {
        margin-bottom: 6px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S18};
        font-family: ${({ theme }) => theme.FONT.STYLE.REGULAR};
        color: ${({ theme }) => theme.COLORS.BLACK_2};
      }
    }
    .rsc_detail {
      text-align: center;
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      color: ${({ theme }) => theme.COLORS.GRAY_5};
      &.mt_5 {
        margin-top: -5px;
      }
      &.mb_16 {
        margin-bottom: 16px;
      }
    }
  }
`;
