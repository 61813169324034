import React, { useState } from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { TabStyled } from './styled';

const Tab = ({ theme_standard, tab, initialValue }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  const [_selected, _setSelected] = useState(initialValue);

  const _handleClickTab = (e) => {
    _setSelected(e);
  };

  return (
    <TabStyled>
      <div className={customClass}>
        <div className="tab_container">
          <div className="tab_header_sec">
            {tab &&
              Object.keys(tab).map((key, i) => (
                <div
                  key={i}
                  className={`tb_h_col ${
                    _selected === tab[key].key ? 'active' : ''
                  }`}
                  onClick={() => _handleClickTab(tab[key].key)}
                >
                  {tab[key].label}
                </div>
              ))}
          </div>
          <div className="tab_body_sec">{tab[_selected].element}</div>
        </div>
      </div>
    </TabStyled>
  );
};

Tab.propTypes = {};

export default Tab;
