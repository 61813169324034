import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { LabelPointVerticalStyled } from './styled';

const LabelPointVertical = ({ theme_standard, label, point }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <LabelPointVerticalStyled>
      <div className={customClass}>
        <div className="lpv_container">
          <div className="lpv_label">{label}</div>
          <div className="lpv_point_label">{point}</div>
        </div>
      </div>
    </LabelPointVerticalStyled>
  );
};

LabelPointVertical.propTypes = {};

export default LabelPointVertical;
