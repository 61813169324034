import styled from 'styled-components';

export const StoreConfirmStyled = styled.div`
  border-radius: 16px;
  width: 100%;
  max-width: 350px;
  padding: 18px 12px;
  background: ${({ theme }) => theme.COLORS.WHITE_2};
  .sc_title {
    margin-bottom: 14px;
  }
  .rg_txt_detail {
    margin-bottom: 8px;
    &.mb_16 {
      margin-bottom: 16px;
    }
  }
  .rg_action_row {
    display: flex;
    column-gap: 16px;
    .rg_action_col {
      flex: 1;
    }
  }
  .store_img_wrap {
    margin-bottom: 18px;
    text-align: center;
    .store_img {
      width: 100%;
      height: auto;
      max-height: 300px;
      border-radius: 10px;
    }
  }
`;
