import styled from 'styled-components';

export const RedeemRewardContainerStyled = styled.div`
  .loading_show {
    height: calc(100vh - 199px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .point_row {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    .point_col {
      flex: 1;
      &.point_line {
        border-right: 1px solid ${({ theme }) => theme.COLORS.WHITE_2};
      }
      .my_reward_wrap {
        padding-left: 18px;
      }
    }
  }
`;
