import styled from 'styled-components';

export const ScanQRCodeFormStyled = styled.div`
  margin: auto;
  max-width: 85%;
  padding: 20px 0;
  .txt_between_input {
    margin: 8px 0;
    text-align: center;
    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    color: ${({ theme }) => theme.COLORS.WHITE_2};
  }
  .input_wrap {
    margin-bottom: 60px;
  }
`;
