import React from 'react';
import { withTranslation } from 'react-i18next';
import { RegisterTypeStoreContainerStyled } from './styled';
import { Typhographys, Forms } from 'components';

class RegisterTypeStoreContainer extends React.Component {
  state = {};

  handleSubmit = (values) => {
    const { onSubmitSuccess } = this.props;
    onSubmitSuccess && onSubmitSuccess(values);
  };

  render() {
    const { t } = this.props;
    return (
      <RegisterTypeStoreContainerStyled useSuspense={false}>
        <div className="regis_type_title">
          <Typhographys.H1Page label={t('Register a store type')} />
        </div>
        <Forms.RegisterTypeStoreForm onSubmit={this.handleSubmit} />
      </RegisterTypeStoreContainerStyled>
    );
  }
}

export default withTranslation('register')(RegisterTypeStoreContainer);
