import React from 'react';
import { withTranslation } from 'react-i18next';
import { NewContainerStyled } from './styled';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { userService } from 'apiServices';
import { IMG_PATH } from 'utils/constants/imgPath';
import { withRouter } from 'navigations/withRouter';
import { Typhographys, Cards, Displays } from 'components';
import { ROUTE_PATH } from 'utils/constants/routePath';

class NewContainer extends React.Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    let res = await Promise.all([this.fetchBanner(), this.fetchNew()]);
    if (res) {
      this.setState({
        isLoading: false,
        bannerList: res[0].docs,
        newsList: res[1].docs,
      });
    }
  };

  fetchBanner = async () => {
    let res = await userService.GET_BANNER_LIST('?is_active=true');
    if (res && res.status === 200) {
      return res.data;
    }
  };

  fetchNew = async () => {
    let res = await userService.GET_NEW_LIST('?is_active=true');
    if (res && res.status === 200) {
      return res.data;
    }
  };

  handleClickDetail = (e) => {
    this.props.router.navigate(`${ROUTE_PATH.NEWS}/${e._id}`, { ...e });
  };

  render() {
    const { t } = this.props;
    const { bannerList, newsList, isLoading } = this.state;
    return (
      <NewContainerStyled useSuspense={false}>
        <div className="title_wrap">
          <Typhographys.H1Page
            label={t('แนะนำสินค้า/ข่าวสาร', {
              ns: 'news',
            })}
          />
        </div>
        {isLoading ? (
          <div className="loading_show">
            <Displays.Loading />
          </div>
        ) : (
          <>
            <div className="banner_wrap">
              <Slider {...settings}>
                {bannerList &&
                  bannerList.map((e, i) => (
                    <a target="_blank" rel="noopener noreferrer" href={e.link}>
                      <div key={i} className="banner_img_wrap">
                        <img
                          className="banner_img"
                          src={
                            e.img_path
                              ? IMG_PATH + e.img_path
                              : '/assets/images/mockup/banner-1.png'
                          }
                          alt="banner img"
                        />
                      </div>
                    </a>
                  ))}
              </Slider>
            </div>
            <div className="product_list">
              {newsList &&
                newsList.map((e, i) => (
                  <div
                    key={i}
                    className="product_item_wrap"
                    onClick={() => this.handleClickDetail(e)}
                  >
                    <Cards.ProductItem
                      name={e.title}
                      imgUri={
                        e.img_path
                          ? IMG_PATH + e.img_path
                          : '/assets/images/mockup/banner-1.png'
                      }
                      description={e.description}
                    />
                  </div>
                ))}
            </div>
          </>
        )}
      </NewContainerStyled>
    );
  }
}

const settings = {
  dots: true,
  infinite: true,
  arrows: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

export default withTranslation('news')(withRouter(NewContainer));
