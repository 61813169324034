import styled from 'styled-components';

export const LogoHeaderStyled = styled.div`
  .logo_header_container {
    background: ${({ theme }) => theme.COLORS.WHITE_2};
    text-align: center;
    .logo_header_wrap {
      padding: 12px 0px 8px 0px;
      .logo_header_img {
        width: 148px;
        height: auto;
      }
    }
    .logo_bottom_line {
      height: 10px;
      background: ${({ theme }) => theme.COLORS.RED_1};
    }
  }

  .theme_standard {
  }
`;
