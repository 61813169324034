import { default as LoginForm } from './LoginForm';
import { default as PDPAForm } from './PDPAForm';
import { default as ProfileForm } from './ProfileForm';
import { default as RegisterStoreInfomationForm } from './RegisterStoreInfomationForm';
import { default as RegisterTypeStoreForm } from './RegisterTypeStoreForm';
import { default as ScanQRCodeForm } from './ScanQRCodeForm';
import { default as VerifyOTPForm } from './VerifyOTPForm';
import { default as ExchangeRedeemDetailForm } from './ExchangeRedeemDetailForm';

const EXPORT = {
  LoginForm,
  PDPAForm,
  ProfileForm,
  RegisterStoreInfomationForm,
  RegisterTypeStoreForm,
  ScanQRCodeForm,
  VerifyOTPForm,
  ExchangeRedeemDetailForm,
};

export default EXPORT;
