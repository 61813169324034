import styled from 'styled-components';

export const RadioStyled = styled.div`
  .radio_input_item {
    margin-bottom: 8px;
    .group_show_radio {
      display: flex;
      align-items: center;
      column-gap: 8px;
      .radio_input {
        margin: 0;
        cursor: pointer;
      }
      .radio_label {
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_LIGHT};
        color: ${({ theme }) => theme.COLORS.GRAY_5};
        cursor: pointer;
      }
    }
    .radio_sub_detial {
      margin-left: 21px;
      font-size: ${({ theme }) => theme.FONT.SIZE.S12};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
      color: ${({ theme }) => theme.COLORS.GRAY_5};
    }
  }
  .errors {
    margin-top: 2px;
    font-size: ${({ theme }) => theme.FONT.SIZE.S12};
    color: ${({ theme }) => theme.COLORS.RED_1};
  }

  .theme_standard {
  }

  .theme_black_regular {
    .radio_input_item {
      margin-bottom: 8px;
      .group_show_radio { 
        display: flex;
        align-items: center;
        column-gap: 8px;
        .radio_input {
          margin: 0;
          cursor: pointer;
        }
        .radio_label {
          font-size: ${({ theme }) => theme.FONT.SIZE.S14};
          font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
          color: ${({ theme }) => theme.COLORS.BLACK_1};
          cursor: pointer;
        }
      }
    }
  }
`;
