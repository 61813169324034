import React from 'react';

export default function renderIcon({
  width = 18,
  height = 15,
  color = '#4E5B7E',
}) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='13.777'
      height='13.777'
      viewBox='0 0 13.777 13.777'
    >
      <g
        id='Group_75615'
        data-name='Group 75615'
        transform='translate(1.414 1.414)'
      >
        <g id='Group_75613' data-name='Group 75613'>
          <line
            id='Line_5791'
            data-name='Line 5791'
            y1='10.948'
            x2='10.948'
            fill='none'
            stroke='#6f8595'
            strokeLinecap='round'
            strokeWidth='2'
          />
          <line
            id='Line_5792'
            data-name='Line 5792'
            x1='10.948'
            y1='10.948'
            fill='none'
            stroke='#6f8595'
            strokeLinecap='round'
            strokeWidth='2'
          />
        </g>
      </g>
    </svg>
  );
}
