import styled from 'styled-components';

export const CheckboxStyled = styled.div`
  .input_checkbox_container {
    display: flex;
    align-items: center;
    column-gap: 8px;
    input {
      flex-shrink: 0;
      width: 18px;
      height: 18px;
    }
    .placeholder {
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_LIGHT};
      color: ${({ theme }) => theme.COLORS.WHITE_2};
    }
  }
  .errors {
    margin-top: 2px;
    font-size: ${({ theme }) => theme.FONT.SIZE.S12};
    color: ${({ theme }) => theme.COLORS.RED_1};
  }

  .theme_standard {
  }

  .theme_gray {
    .placeholder {
      color: ${({ theme }) => theme.COLORS.GRAY_5};
    }
  }
`;
