import { default as AvatarEdit } from './AvatarEdit';
import { default as Camera } from './Camera';
import { default as Cancel } from './Cancel';
import { default as Confirm } from './Confirm';
import { default as Resend } from './Resend';
import { default as MenuHistory } from './MenuHistory';
import { default as MenuMyReward } from './MenuMyReward';
import { default as MenuNew } from './MenuNew';
import { default as MenuProductRegis } from './MenuProductRegis';
import { default as MenuProfile } from './MenuProfile';
import { default as TrackPending } from './TrackPending';
import { default as TrackPrepare } from './TrackPrepare';
import { default as TrackSuccess } from './TrackSuccess';
import { default as Verify } from './Verify';
import { default as CloseX } from './CloseX';
import { default as Loading } from './Loading';

const EXPORT = {
  Loading,
  CloseX,
  AvatarEdit,
  Camera,
  Cancel,
  Confirm,
  Resend,
  MenuHistory,
  MenuMyReward,
  MenuNew,
  MenuProductRegis,
  MenuProfile,
  TrackPending,
  TrackPrepare,
  TrackSuccess,
  Verify,
};

export default EXPORT;
