import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { IconBgStandardStyled } from './styled';

const IconBgStandard = ({
  theme_cancel,
  type = 'button',
  iconPrefix,
  label,
  onClick,
  disabled,
}) => {
  const customClass = cx({
    theme_cancel: theme_cancel,
  });
  return (
    <IconBgStandardStyled disabled={disabled}>
      <div className={customClass}>
        <button
          type={type}
          className="btn"
          onClick={onClick}
          disabled={disabled}
        >
          {iconPrefix}
          {label}
        </button>
      </div>
    </IconBgStandardStyled>
  );
};

IconBgStandard.propTypes = {};

export default IconBgStandard;
