import styled from 'styled-components';

export const RegisterStoreInfomationFormStyled = styled.div`
  .input_col_wrap {
    margin-bottom: 16px;
    &.flex {
      display: flex;
      column-gap: 4px;
    }
    &.mb_8 {
      margin-bottom: 8px;
    }
    &.mb_22 {
      margin-bottom: 22px;
    }
    &.pl_16 {
      padding-left: 16px;
    }
    .click_more {
      margin-left: 5px;
      padding-top: 2px;
      color: ${({ theme }) => theme.COLORS.RED_1};
      cursor: pointer;
    }
  }
`;
