import React from 'react';
import { withTranslation } from 'react-i18next';
import { MessageContainerStyled } from './styled';
import { t } from 'i18next';
import moment from 'moment';
import momentTZ from 'moment-timezone';
import { userService } from 'apiServices';
import { RedeemReward, RewardCodeScan, TrackingReward } from './Modals';
import { SHIPPING_STATUS } from 'utils/functions/shippingStatus';
import { IMG_PATH } from 'utils/constants/imgPath';
import { Cards, Modals, Displays } from 'components';

class MessageContainer extends React.Component {
  state = { isLoading: true };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    let res = await userService.GET_MY_REWARD_HISTORY();
    if (res && res.status === 200) {
      this.setState({
        isLoading: false,
        data: res.data,
      });
    }
  };

  handleClickRewardItem = (e) => {
    switch (e.reward_type) {
      case '1':
        this.onBarcodeRewardTypeFlow(e);
        break;
      case '2':
        this.onProductRewardTypeFlow(e);
        break;
      default:
        break;
    }
  };

  onProductRewardTypeFlow = (e) => {
    switch (e.status) {
      case '2':
      case '3':
      case '4':
        this.setState({
          isShowModal: true,
          renderModal: (
            <TrackingReward
              imgUri={IMG_PATH + e.reward_img_path}
              name={e.reward_name}
              rewardDate={moment(e.created_at).format('DD MMMM YYYY')}
              shippingBrand={e.shipping_brand}
              shippingPhoneNumber={e.shipping_phone_number}
              shippingLink={e.shipping_link}
              shipping_ref_no={e.shipping_ref_no}
              status={e.status}
              discount={500}
              onBack={this.handleCloseModal}
            />
          ),
        });
        break;
      default:
        break;
    }
  };

  onBarcodeRewardTypeFlow = (e) => {
    switch (e.status) {
      case '2':
        let timeBalance = momentTZ(moment(e.updated_at).add(5, 'minutes'))
          .tz('Asia/Bangkok')
          .diff(moment());
        if (timeBalance <= 0) {
          this.setState({
            dataReward: e,
            isShowModal: true,
            renderModal: (
              <RedeemReward
                imgUri={IMG_PATH + e.reward_img_path}
                name={e.reward_name}
                rewardType={e.reward_type}
                rewardDate={moment(e.created_at).format('DD MMMM YYYY')}
                status={e.status}
                onBack={this.handleCloseModal}
                onUseBarcode={() => this.onUseBarcode(e)}
              />
            ),
          });
        } else {
          this.setState({
            dataReward: e,
            isShowModal: true,
            renderModal: (
              <RewardCodeScan
                {...e}
                timeBalance={timeBalance}
                onBack={this.handleCloseModal}
                _onTimeoutBarcode={this.handleCloseModal}
              />
            ),
          });
        }

        break;
      case '3':
      case '4':
        this.setState({
          dataReward: e,
          isShowModal: true,
          renderModal: (
            <RedeemReward
              imgUri={IMG_PATH + e.reward_img_path}
              name={e.reward_name}
              rewardType={e.reward_type}
              rewardDate={moment(e.created_at).format('DD MMMM YYYY')}
              status={e.status}
              onBack={this.handleCloseModal}
              onUseBarcode={() => this.onUseBarcode(e)}
            />
          ),
        });
        break;
      default:
        break;
    }
  };

  handleRedeemSuccess = ({ name }) => {
    this.setState({
      renderModal: (
        <RewardCodeScan name={name} onBack={this.handleCloseModal} />
      ),
    });
  };

  handleCloseModal = () => {
    this.setState({
      isShowModal: false,
    });
  };

  render() {
    // const { t } = this.props;
    const { isShowModal, renderModal, isLoading, data } = this.state;
    return (
      <MessageContainerStyled useSuspense={false}>
        {isLoading ? (
          <div className="loading_show">
            <Displays.Loading />
          </div>
        ) : (
          <>
            <div className="reward_container">
              <div className="rc_title">
                {t('My Reward', { ns: 'message' })}
              </div>
              <div className="rc_list">
                {!data || data.length === 0 ? (
                  <div className="no_data_txt">
                    {t('No Data', { ns: 'common' })}
                  </div>
                ) : (
                  data.map((e, i) => (
                    <div key={i} className="item_wrap">
                      <Cards.RewardItem
                        imgUri={IMG_PATH + e.reward_img_path}
                        name={e.reward_name}
                        rewardDate={moment(e.created_at).format('DD MMMM YYYY')}
                        status={SHIPPING_STATUS(e.reward_type, e.status)}
                        onClick={() => this.handleClickRewardItem(e)}
                      />
                    </div>
                  ))
                )}
              </div>
            </div>
            <Modals.BodyEmpty
              isShowModal={isShowModal}
              onCloseModal={this.handleCloseModal}
            >
              {renderModal}
            </Modals.BodyEmpty>
          </>
        )}
      </MessageContainerStyled>
    );
  }
}

export default withTranslation(['message', 'common'])(MessageContainer);
