import React from "react";
// import PropTypes from 'prop-types'
import { RedeemRewardStyled } from "./styled";
import { useTranslation } from "react-i18next";
import { RenderCommaMoney } from "utils/functions/FormatMoney";
import { Buttons } from "components";

const RedeemReward = ({
  imgUri,
  name,
  total_value,
  // rewardDate,
  reward_cate,
  description,
  memo,
  onBack,
  onRedeemSuccess,
}) => {
  const { t } = useTranslation(["message", "common"]);

  return (
    <RedeemRewardStyled>
      <img className="r_reward_img" src={imgUri} alt="reward product" />
      <div className="redeem_container">
        <div className="redeem_row">
          <div className="title_red">
            {reward_cate === "1" ? "คูปองส่วนลด" : "สินค้าพรีเมี่ยม"}
          </div>
          <div className="redeem_label">
            {t("Reward")} : <span className="redeem_value">{name}</span>
          </div>
          <div className="redeem_label">
            มูลค่า :{" "}
            <span className="redeem_value">
              {RenderCommaMoney(total_value)} บาท
            </span>
          </div>
          <div className="group_des">
            <div>{t("Description", { ns: "message" })}</div>
            <div className="sub_des">{description}</div>
          </div>
          <div className="group_des mt">
            <div className="sub_des">{memo}</div>
          </div>
        </div>
      </div>
      <div className="redeem_action_row">
        <div className="redeem_action_col">
          <Buttons.BgStandard
            theme_cancel
            label={t("Back", { ns: "common" })}
            onClick={onBack}
          />
        </div>
        <div className="redeem_action_col">
          <Buttons.BgStandard
            label={t("Redeem rewards", { ns: "common" })}
            onClick={onRedeemSuccess}
          />
        </div>
      </div>
    </RedeemRewardStyled>
  );
};

RedeemReward.propTypes = {};

export default RedeemReward;
