import styled from 'styled-components';

export const PDPAFormStyled = styled.div`
  .pdpa_title {
    margin-bottom: 8px;
    text-align: center;
    font-size: ${({ theme }) => theme.FONT.SIZE.S20};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
    color: ${({ theme }) => theme.COLORS.BLACK_2};
  }
  .pdpa_content {
    margin-bottom: 8px;
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
    color: ${({ theme }) => theme.COLORS.GRAY_5};
  }
  .pdpa_cancel {
    margin-bottom: 12px;
    color: ${({ theme }) => theme.COLORS.RED_1};
  }
  .pdpa_input_wrap {
    margin-bottom: 20px;
  }
  .pdpa_action_row {
    display: flex;
    column-gap: 16px;
    .pdpa_action_col {
      flex: 1;
    }
  }
`;
