import styled from 'styled-components';

export const RewardItemStyled = styled.div`
  .reward_item_row {
    display: flex;
    align-items: flex-end;
    column-gap: 12px;
    cursor: pointer;
    .item_left_col {
      .figture_img {
        border-radius: 3px;
        -webkit-box-shadow: 0px 3px 6px 0px #bfbfbf;
        box-shadow: 0px 3px 6px 0px #bfbfbf;
        width: 158px;
        height: 112px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: ${({ theme }) => theme.COLORS.WHITE_2};
        img {
          border-radius: 3px;
          width: 154px;
          height: 108px;
          object-fit: cover;
        }
      }
    }
    .item_right_col {
      flex: 1;
      .data_row {
        display: flex;
        align-items: baseline;
        .dr_label {
          font-size: ${({ theme }) => theme.FONT.SIZE.S13};
          font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
          color: ${({ theme }) => theme.COLORS.BLACK_2};
        }
        .dr_value {
          padding-left: 4px;
          font-size: ${({ theme }) => theme.FONT.SIZE.S12};
          font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_LIGHT};
          color: ${({ theme }) => theme.COLORS.RED_1};
        }
      }
    }
  }

  .theme_standard {
  }
`;
