import styled from 'styled-components';

export const UploadStyled = styled.div`
  .dropzone {
    border: 1px solid ${({ theme }) => theme.COLORS.GRAY_4};
    border-radius: 6px;
    width: 100%;
    height: 140px;
    background: ${({ theme }) => theme.COLORS.WHITE_2};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .camera {
      text-align: center;
      .camera_label {
        color: ${({ theme }) => theme.COLORS.GRAY_5};
      }
    }
  }
  .img_preview {
    margin-top: 12px;
    width: 100%;
    // height: auto;
    height: 200px;
    object-fit: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    .close {
      position: absolute;
      right: 4px;
      top: 6px;
      cursor: pointer;
      z-index: 2;
      background: #ffff;
      padding: 5px;
      border-radius: 50%;
      height: 10px;
      width: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .theme_standard {
  }
`;
