import React from 'react';
import { withTranslation } from 'react-i18next';
import { RegisProductContainerStyled } from './styled';
import { withRouter } from 'navigations/withRouter';
import { connect } from 'react-redux';
// import { AlertReward } from './Modals';
import { ROUTE_PATH } from 'utils/constants/routePath';
import { userService } from 'apiServices';
import { AlertControl } from 'components/Modals/Alert';
import { setReduxAuthen } from 'store/actions';
import { Buttons, Modals, Typhographys, Displays, Cards } from 'components';

class RegisProductContainer extends React.Component {
  state = { isLoading: true };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    let res = await userService.GET_SERIAL_NO(this.props.router.params.id);
    if (res && res.status === 200) {
      this.setState({
        data: res.data,
        isLoading: false,
      });
    } else {
      this.setState({
        data: null,
        isLoading: false,
      });
    }
  };

  handleCloseModal = () => {
    this.setState({
      isShowModal: false,
    });
  };

  handleClickBack = () => {
    this.props.router.navigate(ROUTE_PATH.PRODUCT_SCAN_QR_CODE);
  };

  handleClickRegis = async () => {
    const { t } = this.props;
    const { data } = this.state;
    this.setState({
      isBGLoading: true,
    });
    let params = {
      product_registed_ref_id: data._id,
    };
    let res = await userService.POST_ACTIVE_RETAILER(params);
    if (res && res.status === 200) {
      this.fetchData();
      AlertControl.show({
        title: t('Register success', { ns: 'reward' }),
        description: t('You have already received the Point', {
          ns: 'reward',
        }),
        btnLabel2: t('Back2', { ns: 'common' }),
      });
      let resProfile = await userService.GET_MY_PROFILE();
      if (resProfile && resProfile.status === 200) {
        this.setState({
          isBGLoading: false,
        });
        this.props.setReduxAuthen(resProfile.data);
      }
    } else {
      this.setState({
        isBGLoading: false,
      });
      AlertControl.show({
        title: t('Register failed', { ns: 'reward' }),
        // description: t('You have already received the Point', {
        //   ns: 'reward',
        // }),
        btnLabel2: t('Back2', { ns: 'common' }),
      });
    }
  };

  render() {
    const { t } = this.props;
    const { isShowModal, renderModal, data, isLoading, isBGLoading } =
      this.state;
    return (
      <RegisProductContainerStyled useSuspense={false}>
        {isLoading ? (
          <div className="loading_show">
            <Displays.Loading />
          </div>
        ) : (
          <>
            <Displays.BGLoading visible={isBGLoading} />
            <div className="regis_product_title">
              <Typhographys.H1Page
                label={t('Check customer status', { ns: 'registerproduct' })}
              />
            </div>
            {!data ? (
              <div className="regis_no_data_content">
                <div className="rn_label fs_18 mb_2">สถานะลูกค้า</div>
                <div className="rn_label red mb_10">
                  ลูกค้ายังไม่ได้ลงทะเบียน
                </div>
                <div className="rn_label">หมายเลข Serial Number :</div>
                <div className="rn_label mb_20">
                  {this.props.router.params.id}
                </div>
                <Buttons.BgStandard
                  theme_cancel
                  label={t('Back', { ns: 'common' })}
                  onClick={this.handleClickBack}
                />
              </div>
            ) : (
              <div className="regis_product_content">
                <div className="brand_img_wrap">
                  <img
                    className="brand_img"
                    src="/assets/images/mockup/product.png"
                    alt="brand"
                  />
                </div>
                <div className="content_sec">
                  <Cards.OrderList {...data} />
                </div>
                <div className="action_row">
                  {!data.retailer_ref_id && (
                    <div className="rp_action_wrap">
                      <Buttons.BgStandard
                        theme_cancel
                        label={t('Register', { ns: 'common' })}
                        onClick={this.handleClickRegis}
                      />
                    </div>
                  )}
                  <div className="rp_action_wrap">
                    <Buttons.BgStandard
                      label={t('Back2', { ns: 'common' })}
                      onClick={this.handleClickBack}
                    />
                  </div>
                </div>
              </div>
            )}
            <Modals.BodyEmpty
              isShowModal={isShowModal}
              onCloseModal={this.handleCloseModal}
            >
              {renderModal}
            </Modals.BodyEmpty>
          </>
        )}
      </RegisProductContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxAuthen: (data) => dispatch(setReduxAuthen(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withTranslation(['registerproduct', 'common', 'reward'])(
    withRouter(RegisProductContainer)
  )
);
