export default function renderIcon({ color = '#fff' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35.091"
      height="22.165"
      viewBox="0 0 35.091 22.165"
    >
      <g
        id="Group_378"
        data-name="Group 378"
        transform="translate(-8.901 -16.528)"
      >
        <path
          id="Path_836"
          data-name="Path 836"
          d="M31.516,61.628H29.273c-.8,0-1.6,0-2.4,0a1.163,1.163,0,1,1-.006-2.326c1.6-.008,3.2,0,4.8,0h.373l.544-2.467H32.19c-1.108,0-2.216.006-3.324,0a1.206,1.206,0,0,1-1.243-1.187A1.174,1.174,0,0,1,28.856,54.5c.479,0,.958,0,1.437,0H33.1l.555-2.488c-.6,0-1.181.021-1.757-.009a2.22,2.22,0,0,1-.846-.2,1.076,1.076,0,0,1-.507-1.172,1.093,1.093,0,0,1,.93-.919c.726-.044,1.456-.032,2.184-.043.159,0,.318,0,.512,0,.072-.312.139-.618.214-.922a1.227,1.227,0,0,1,1.275-1.009q9.03,0,18.059,0a1.2,1.2,0,0,1,1.229,1.607q-.611,2.55-1.232,5.1-1.056,4.364-2.112,8.729a.7.7,0,0,1-.351.535,4.4,4.4,0,0,0-2.395,3.617.359.359,0,0,1-.039.083h-7.2a16.912,16.912,0,0,0-.652-1.845,4.487,4.487,0,0,0-4.388-2.377,4.558,4.558,0,0,0-4.074,3.768c-.091.471-.092.457-.6.472-1,.03-1.375-.582-1.379-1.136a5.491,5.491,0,0,1,.215-1.169c.251-1.153.508-2.3.769-3.483"
          transform="translate(-16.802 -31.199)"
          fill={color}
        />
        <path
          id="Path_837"
          data-name="Path 837"
          d="M110.451,89.215a4.633,4.633,0,0,0-4.388-4.246c.3-1.228.6-2.447.917-3.662a.31.31,0,0,1,.238-.164c1.7-.009,3.4-.016,5.106,0a1.084,1.084,0,0,1,.969,1.308c-.231.819-.512,1.625-.776,2.435q-.553,1.7-1.113,3.392a1.192,1.192,0,0,1-.953.94"
          transform="translate(-69.333 -53.036)"
          fill={color}
        />
        <path
          id="Path_838"
          data-name="Path 838"
          d="M51.957,101.358a2.167,2.167,0,0,1,2.125-2.3,2.194,2.194,0,1,1,.126,4.385,2.158,2.158,0,0,1-2.251-2.082"
          transform="translate(-33.958 -64.75)"
          fill={color}
        />
        <path
          id="Path_839"
          data-name="Path 839"
          d="M99.264,101.081a2.191,2.191,0,1,1,2.117,2.342,2.149,2.149,0,0,1-2.117-2.342"
          transform="translate(-64.883 -64.739)"
          fill={color}
        />
        <path
          id="Path_840"
          data-name="Path 840"
          d="M114.089,68.272h-3.917l.99-3.964c.644,0,1.271-.016,1.9,0a1.119,1.119,0,0,1,1.022,1.06c.031.955.009,1.912.009,2.9"
          transform="translate(-72.019 -42.034)"
          fill={color}
        />
      </g>
    </svg>
  );
}
