import { default as LogoHeader } from './LogoHeader';
import { default as LabelPointVertical } from './LabelPointVertical';
import { default as Loading } from './Loading';
import { default as BGLoading } from './BGLoading';

const EXPORT = {
  LogoHeader,
  LabelPointVertical,
  Loading,
  BGLoading,
};

export default EXPORT;
