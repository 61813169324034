import React from 'react';
import { withTranslation } from 'react-i18next';
import { RewardContainerStyled } from './styled';
import { SpinWheelWidget } from 'widgets';
// import { userService } from 'apiServices';
import { AlertControl } from 'components/Modals/Alert';
import { Typhographys, Buttons } from 'components';

class RewardContainer extends React.Component {
  state = {};

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {};

  handleClickSpinWheel = () => {
    const { t } = this.props;
    AlertControl.show({
      title: t('Congratulations', { ns: 'reward' }),
      description: t('You have received a VOUCHER. Check at personal mail', {
        ns: 'reward',
      }),
      btnLabel2: t('OK', { ns: 'common' }),
    });
  };

  render() {
    const { t } = this.props;
    return (
      <RewardContainerStyled useSuspense={false}>
        <Typhographys.H1Page
          label={t('You are eligible to spin the wheel times', {
            ns: 'reward',
            point: '9',
          })}
        />
        <div className="spin_wheel_wrap">
          <SpinWheelWidget />
        </div>
        <Buttons.BgStandard
          label={t('Spin the wheel', { ns: 'common' })}
          onClick={this.handleClickSpinWheel}
        />
      </RewardContainerStyled>
    );
  }
}

export default withTranslation(['reward', 'common'])(RewardContainer);
