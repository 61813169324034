import styled from 'styled-components';

export const LabelPointVerticalStyled = styled.div`
  .lpv_container {
    .lpv_label {
      text-align: center;
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
      color: ${({ theme }) => theme.COLORS.WHITE_2};
    }
    .lpv_point_label {
      text-align: center;
      font-size: ${({ theme }) => theme.FONT.SIZE.S30};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
      color: ${({ theme }) => theme.COLORS.YELLOW_1};
    }
  }

  .theme_standard {
  }
`;
