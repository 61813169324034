import styled from 'styled-components';

export const ProductRewardStyled = styled.div`
  .product_reward_container {
    .product_reward_preview {
      margin-bottom: 8px;
      width: 100%;
      height: 200px;
      position: relative;
      .pr_img {
        width: 100%;
        height: 200px;
        object-fit: cover;
      }
      .point_container {
        position: absolute;
        top: 8px;
        right: 6px;
        border-radius: 3px;
        padding: 2px 12px;
        background: ${({ theme }) => theme.COLORS.WHITE_2};
        text-align: center;
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
        color: ${({ theme }) => theme.COLORS.BLACK_2};
      }
      .price_container {
        position: absolute;
        top: 8px;
        left: 6px;
        border-radius: 3px;
        padding: 2px 12px;
        background: ${({ theme }) => theme.COLORS.WHITE_2};
        text-align: center;
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
        color: ${({ theme }) => theme.COLORS.BLACK_2};
      }
    }
    .reward_name {
      text-align: center;
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
      color: ${({ theme }) => theme.COLORS.BLACK_2};
    }
  }

  .theme_standard {
  }
`;
