import apiService from "../apiService";

const apiPath = "/api/v1/retailer";
const apiPortalPath = "/api/v1/portal";

export const userService = {
  // SING IN
  POST_LOGIN: (params) => {
    return apiService.post(`${apiPath}/login`, params);
  },
  POST_LINE_LOGIN: (params) => {
    return apiService.post(`${apiPath}/login/line`, params);
  },
  POST_TOKEN_LOGIN: () => {
    return apiService.post(`${apiPath}/login/token`);
  },
  POST_CHECK_TOKEN: () => {
    return apiService.get(`${apiPath}/login/check_token`);
  },

  // RETAILER
  GET_MY_PROFILE: () => {
    return apiService.get(`${apiPath}/retailer/myProfile`);
  },
  GET_RETAILER_BY_SHOP_NO: (id) => {
    return apiService.get(`${apiPath}/retailer/shopno/${id}`);
  },

  POST_RETAILER: (params) => {
    return apiService.post(`${apiPath}/retailer`, params);
  },
  POST_UPLOAD_RETAILER: (params) => {
    return apiService.post_formdata(`${apiPath}/retailer/upload`, params);
  },
  POST_DELETE_UPLOAD_RETAILER: (params) => {
    return apiService.post(`${apiPath}/retailer/deleteUpload`, params);
  },
  PUT_RETAILER: (id, params) => {
    return apiService.put(`${apiPath}/retailer/${id}`, params);
  },
  PUT_RETAILER_BRANCH: (id, params) => {
    return apiService.put(`${apiPath}/retailer/branch/${id}`, params);
  },

  // OTP
  POST_OTP_REQUEST: (params) => {
    return apiService.post(`${apiPortalPath}/otp_history/request`, params);
  },
  POST_OTP_VERIFY: (params) => {
    return apiService.post(`${apiPortalPath}/otp_history/verify`, params);
  },

  // REWARD
  GET_REWARD_LIST: (queryString) => {
    return apiService.get(
      `${apiPortalPath}/reward${queryString ? queryString : ""}`
    );
  },
  POST_REWARD_REDEEM: (params) => {
    return apiService.post(`${apiPortalPath}/reward/redeem`, params);
  },

  // REWARD HISTORY
  GET_MY_REWARD_HISTORY: () => {
    return apiService.get(`${apiPortalPath}/reward_history/my_redeem`);
  },
  POST_REWARD_ACTIVE_BARCODE: (params) => {
    return apiService.post(
      `${apiPortalPath}/reward_history/active_barcode`,
      params
    );
  },

  // PRODUCT HISTORY
  GET_MY_PRODUCT_HISTORY: () => {
    return apiService.get(`${apiPortalPath}/product_registed/my_product_regis`);
  },

  //PRODUCT REGISTED
  GET_SERIAL_NO: (id) => {
    return apiService.get(`${apiPortalPath}/product_registed/serial_no/${id}`);
  },
  POST_ACTIVE_RETAILER: (params) => {
    return apiService.post(
      `${apiPortalPath}/product_registed/active_retailer`,
      params
    );
  },

  //BANNER
  GET_BANNER_LIST: (queryString) => {
    return apiService.get(
      `${apiPath}/r_banner${queryString ? queryString : ""}`
    );
  },

  //NEW
  GET_NEW_LIST: (queryString) => {
    return apiService.get(`${apiPath}/r_news${queryString ? queryString : ""}`);
  },
  GET_NEW_DETAIL: (id) => {
    return apiService.get(`${apiPath}/r_news/${id}`);
  },

  GET_SYSTEM_CONFIG: () => {
    return apiService.get(`${apiPath}/system-config`);
  },
};
