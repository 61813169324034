import styled from 'styled-components';

export const TrackingRewardStyled = styled.div`
  border-radius: 16px;
  width: 100%;
  max-width: 350px;
  padding: 18px 12px;
  background: ${({ theme }) => theme.COLORS.WHITE_2};
  .tracking_action_tile {
    text-align: center;
    font-size: ${({ theme }) => theme.FONT.SIZE.S22};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
    color: ${({ theme }) => theme.COLORS.RED_1};
  }
  .reward_name {
    text-align: center;
    font-size: ${({ theme }) => theme.FONT.SIZE.S18};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
    color: ${({ theme }) => theme.COLORS.BLACK_2};
  }
  .reward_price {
    margin-top: -5px;
    margin-bottom: 16px;
    text-align: center;
    font-size: ${({ theme }) => theme.FONT.SIZE.S16};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_LIGHT};
    color: ${({ theme }) => theme.COLORS.BLACK_2};
  }
  .tracking_reward_img {
    margin-bottom: 8px;
    width: 100%;
    height: auto;
  }
  .tracking_container {
    margin: auto;
    margin-bottom: 16px;
    width: fit-content;
    .tracking_row {
      display: flex;
      align-items: baseline;
      .tracking_label {
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_LIGHT};
        color: ${({ theme }) => theme.COLORS.BLACK_2};
      }
      .tracking_value {
        padding-left: 4px;
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_LIGHT};
        color: ${({ theme }) => theme.COLORS.RED_1};
      }
    }
  }
  .delivery_title {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 12px;
    .txt_title {
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
      color: ${({ theme }) => theme.COLORS.BLACK_2};
    }
  }
  .delivery_sub_title {
    text-align: center;
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
    color: ${({ theme }) => theme.COLORS.RED_1};
  }
  .deliver_status {
    margin-top: 12px;
    margin-bottom: 16px;
  }
  .btn_back_wrap {
    margin-top: 12px;
  }
`;
