import styled from 'styled-components';

export const RegisProductContainerStyled = styled.div`
  .loading_show {
    height: calc(100vh - 199px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .regis_product_title {
    margin-bottom: 16px;
  }
  .regis_no_data_content {
    border-radius: 16px;
    background: ${({ theme }) => theme.COLORS.WHITE_2};
    padding: 34px 32px 22px 32px;
    .rn_label {
      margin-bottom: 4px;
      text-align: center;
      font-size: ${({ theme }) => theme.FONT.SIZE.S16};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
      color: ${({ theme }) => theme.COLORS.BLACK_2};
      &.red {
        color: ${({ theme }) => theme.COLORS.RED_1};
      }
      &.fs_18 {
        font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      }
      &.mb_2 {
        margin-bottom: 2px;
      }
      &.mb_10 {
        margin-bottom: 10px;
      }
      &.mb_20 {
        margin-bottom: 20px;
      }
    }
  }
  .regis_product_content {
    border-radius: 16px;
    background: ${({ theme }) => theme.COLORS.WHITE_2};
    padding: 34px 32px 22px 32px;
    .brand_img_wrap {
      margin-bottom: 12px;
      text-align: center;
      .brand_img {
        width: 130px;
        height: auto;
      }
    }
    .content_sec {
      margin-bottom: 20px;
    }
    .action_row {
      display: flex;
      column-gap: 15px;
      .rp_action_wrap {
        flex: 1;
      }
    }
  }
`;
