import React from 'react';
import { LoginFormStyled } from './styled';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Inputs, Buttons } from 'components';

const LoginForm = ({ onSubmit }) => {
  const { t } = useTranslation(['form', 'common']);

  const schema = yup.object().shape({
    username: yup.string().required('Username is required'),
    password: yup.string().required('Password is required'),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <LoginFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="f_title">{t('Sign in to SMP', { ns: 'form' })}</div>
        <div className="input_wrap mb_24">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                label="Username"
                placeholder={t('Username', { ns: 'form' })}
                errors={errors.username?.message}
              />
            )}
            name="username"
            defaultValue=""
          />
        </div>
        <div className="input_wrap mb_24">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                type="password"
                label="Password"
                placeholder={t('Password', { ns: 'form' })}
                errors={errors.password?.message}
              />
            )}
            name="password"
            defaultValue=""
          />
        </div>
        <Buttons.BgStandard
          label={t('Sign in', { ns: 'common' })}
          type="submit"
        />
      </form>
    </LoginFormStyled>
  );
};

LoginForm.propTypes = {};

export default LoginForm;
