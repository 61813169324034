import styled from "styled-components";

export const PageLayoutStyled = styled.div`
  margin: 0 auto;
  position: relative;
  max-width: ${({ theme }) => theme.SCREENS.MOBILE};
  min-height: 100vh;
  // background-color: ${({ theme }) => theme.COLORS.RED_2};
  background-image: ${({ bgUrl }) =>
    bgUrl ? `url(${bgUrl})` : "url(/assets/images/bg/red-bg.jpg)"};
  background-position: center;
  background-size: cover;
  .page_header_layout {
    position: absolute;
    top: 17px;
    left: 0;
    width: 100%;
  }
  .main_content_layout {
    padding: 118px 20px 20px 20px;
  }
`;
