import styled from 'styled-components';

export const RewardCodeScanStyled = styled.div`
  border-radius: 16px;
  width: 100%;
  max-width: 350px;
  padding: 18px 12px;
  background: ${({ theme }) => theme.COLORS.WHITE_2};
  .cs_header_sec {
    margin-bottom: 16px;
    border-bottom: 1px solid ${({ theme }) => theme.COLORS.GRAY_4};
    .cs_product_tile {
      text-align: center;
      font-size: ${({ theme }) => theme.FONT.SIZE.S22};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
      color: ${({ theme }) => theme.COLORS.RED_1};
    }
    .cs_product_sub_title {
      margin-bottom: 8px;
      text-align: center;
      font-size: ${({ theme }) => theme.FONT.SIZE.S18};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
      color: ${({ theme }) => theme.COLORS.BLACK_2};
    }
  }
  .cs_content_sec {
    margin-bottom: 16px;
    .sdc_title_symbol {
      margin-bottom: 12px;
      text-align: center;
      font-size: ${({ theme }) => theme.FONT.SIZE.S17};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR};
      color: ${({ theme }) => theme.COLORS.BLACK_2};
    }
    .barcode_wrap {
      text-align: center;
    }
    .cs_label {
      text-align: center;
      font-size: ${({ theme }) => theme.FONT.SIZE.S14};
      font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_LIGHT};
      color: ${({ theme }) => theme.COLORS.BLACK_2};
      .highlight {
        color: ${({ theme }) => theme.COLORS.RED_1};
      }
    }
  }
`;
