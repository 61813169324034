import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { ProfileContainerStyled } from './styled';
import { userService } from 'apiServices';
import { toast } from 'react-toastify';
import { setReduxAuthen } from 'store/actions';
import { Typhographys, Forms, Displays } from 'components';

class ProfileContainer extends React.Component {
  state = {};

  componentDidMount() {
    this.fetchData();
  }

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  fetchData = async (isForceFetch) => {
    const { authenRedux } = this.props;
    if (!isForceFetch) {
      setTimeout(() => {
        this.setState({
          initialValues: authenRedux,
          isLoading: false,
        });
      }, 400);
    } else {
      let res = await userService.GET_MY_PROFILE();
      if (res && res.status === 200) {
        this.props.setReduxAuthen(res.data);
      }
    }
  };

  handleClickChangLang = () => {
    const { i18n } = this.props;
    i18n.changeLanguage('en');
  };

  handleSubmit = async (values) => {
    const { t, authenRedux } = this.props;
    let params = {
      firstname: values.firstname,
      lastname: values.lastname,
      phone_number: values.phone_number,
      address: values.address,
      province: values.province.value,
      district: values.district.value,
      sub_district: values.sub_district.value,
      zipcode: values.zipcode,
    };
    let res = await userService.PUT_RETAILER(authenRedux._id, params);
    if (res && res.status === 200) {
      const filesTemp = [
        ...values.img_path.filter((e) => e.isNewUpload || e.isDelete),
      ];
      if (filesTemp && filesTemp.length > 0) {
        this.handleUploadContentMedia(authenRedux._id, filesTemp);
      } else {
        this.fetchData(true);
        toast.success(t('Update success', { ns: 'translation' }));
      }
    } else {
      toast.error(t('Update failed', { ns: 'translation' }));
    }
  };

  handleUploadContentMedia = (id, files) => {
    const { t } = this.props;
    Promise.all(
      files.map((e) => {
        return new Promise(async (resolve) => {
          if (e.isNewUpload) {
            const bodyFormData = new FormData();
            bodyFormData.set('_id', id);
            bodyFormData.append('image', e);
            let res = await userService.POST_UPLOAD_RETAILER(bodyFormData);
            if (res && res.status === 200) {
              resolve();
            }
          }
          if (e.isDelete) {
            let params = {
              _id: id,
              file: e.media_uri,
            };
            let res = await userService.POST_DELETE_UPLOAD_RETAILER(params);
            if (res && res.status === 200) {
              resolve();
            }
          }
        });
      })
    ).then(() => {
      this.fetchData(true);
      toast.success(t('Update success', { ns: 'translation' }));
    });
  };

  render() {
    const { t, authenRedux } = this.props;
    const { isLoading } = this.state;
    return (
      <ProfileContainerStyled useSuspense={false}>
        {isLoading ? (
          <div className="loading_show">
            <Displays.Loading />
          </div>
        ) : (
          <>
            <Typhographys.H1Page
              label={t('ข้อมูลลูกค้า', {
                ns: 'profile',
              })}
            />
            <Forms.ProfileForm
              initialValues={authenRedux}
              onSubmit={this.handleSubmit}
            />
          </>
        )}
      </ProfileContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxAuthen: (data) => dispatch(setReduxAuthen(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('profile')(ProfileContainer));
