import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import PropTypes from 'prop-types'
import { VerifyOTPStyled } from './styled';
import { userService } from 'apiServices';
import { Forms } from 'components';
import { toast } from 'react-toastify';

const VerifyOTP = ({ phoneNumber, onCancel, onVerifySuccess }) => {
  const { t } = useTranslation(['form']);

  useEffect(() => {
    _requestOTP();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [_dataOTP, _setDataOTP] = useState();

  const _requestOTP = async () => {
    let params = {
      phone: phoneNumber.replace(0, '66'),
      sender_type: '1',
    };
    let res = await userService.POST_OTP_REQUEST(params);
    if (res && res.status === 200) {
      _setDataOTP(res.data);
    }
  };

  const _handleClickResendOTP = () => {
    _requestOTP();
  };

  const _handleSubmit = async (values) => {
    let params = {
      request_id: _dataOTP.request_id,
      code: values.code,
    };
    let res = await userService.POST_OTP_VERIFY(params);
    if (res && res.status === 200) {
      onVerifySuccess && onVerifySuccess();
    } else {
      toast.error(t('OTP code invalid', { ns: 'form' }));
    }
  };

  return (
    <VerifyOTPStyled>
      <div className="v_otp_title">
        <Forms.VerifyOTPForm
          phoneNumber={phoneNumber}
          onCancel={onCancel}
          onClickResendOTP={_handleClickResendOTP}
          onSubmit={_handleSubmit}
        />
      </div>
    </VerifyOTPStyled>
  );
};

VerifyOTP.propTypes = {};

export default VerifyOTP;
