import React, { useState, useEffect } from 'react';
import { UploadStyled } from './styled';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone';
import { Icons } from 'components';

const Upload = ({
  theme_standard,
  onChange,
  acceptType,
  value,
  maxMBFileSizeWarning,
  placeholder,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  const [_files, _setFiles] = useState([]);

  const renderAcceptType = (type) => {
    switch (type) {
      case 'cover':
        return 'image/*';
      case 'audio':
        return '.mp3,audio/mpeg3';
      case 'video':
        return 'video/mp4';
      default:
        break;
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: renderAcceptType(acceptType),
    onDrop: (acceptedFiles) => {
      if (maxMBFileSizeWarning) {
        let checkFileLimit = acceptedFiles.filter(
          (e) =>
            Number((e.size / 1024 / 1024).toFixed(2)) >
            Number(maxMBFileSizeWarning)
        );
        if (checkFileLimit && checkFileLimit.length > 0) {
          alert('ไฟล์มีขนาดใหญ่ ผู้ใช้อาจต้องใช้เวลานานในการแสดงผล');
        }
      }
      let fileTemp = [
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            isNewUpload: true,
          })
        ),
      ];
      _setFiles(fileTemp);
      onChange && onChange(fileTemp);
    },
  });

  useEffect(() => {
    if (value) {
      _setFiles(value);
    }
  }, [value]);

  const onClickRemove = (index, data) => {
    if (data.isNewUpload) {
      let filesTemp = _files;
      filesTemp.splice(index, 1);
      _setFiles([...filesTemp]);
    } else {
      let filesTemp = _files;
      filesTemp[index].isDelete = true;
      _setFiles([...filesTemp]);
    }
  };

  return (
    <UploadStyled>
      <div className={customClass}>
        {_files.length < 1 && (
          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            <div className="camera">
              <Icons.Camera />
              <div className="camera_label">{placeholder}</div>
            </div>
          </div>
        )}
        <aside>
          {_files &&
            _files.length > 0 &&
            _files.map((e, i) =>
              e.isNewUpload ? (
                <div
                  key={i}
                  className="img_preview"
                  // src={e.preview}
                  style={{ backgroundImage: `url(${e.preview})` }}
                  alt="upload img"
                >
                  <div className="close" onClick={() => onClickRemove(i, e)}>
                    <Icons.CloseX />
                  </div>
                </div>
              ) : (
                // <Cards.PreviewInput
                //   key={i}
                //   type={acceptType}
                //   src={e.preview}
                //   name={e.name}
                //   onClickRemove={() => onClickRemove(i, e)}
                //   svg={svg}
                //   theme_left_right={theme_left_right}
                // />
                !e.isDelete && (
                  <div
                    key={i}
                    className="img_preview"
                    // src={e.media_uri}
                    style={{ backgroundImage: `url(${e.image_uri})` }}
                    alt="upload img"
                  >
                    <div className="close" onClick={() => onClickRemove(i, e)}>
                      <Icons.CloseX />
                    </div>
                  </div>
                )
              )
            )}
        </aside>
      </div>
    </UploadStyled>
  );
};

Upload.propTypes = {};

export default Upload;
