import styled from 'styled-components';

export const ProductItemStyled = styled.div`
  .product_item_row {
    border-radius: 16px;
    display: flex;
    column-gap: 12px;
    padding: 12px;
    background: ${({ theme }) => theme.COLORS.WHITE_2};
    cursor: pointer;
    .item_left_col {
      width: 114px;
      height: 114px;
      flex-shrink: 0;
      img {
        border-radius: 3px;
        width: 114px;
        height: 114px;
        object-fit: cover;
      }
    }
    .item_right_col {
      width: 70%;
      .pi_title {
        font-size: ${({ theme }) => theme.FONT.SIZE.S16};
        font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_REGULAR  };
        color: ${({ theme }) => theme.COLORS.BLACK_2};
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .pi_sub_title {
        font-size: ${({ theme }) => theme.FONT.SIZE.S14};
        font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_LIGHT};
        color: ${({ theme }) => theme.COLORS.BLACK_2};
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .theme_standard {
  }
`;
