import styled from 'styled-components';

export const DropdownSelectStyled = styled.div`
  .label {
  }
  .control {
    border: 1px solid ${({ theme }) => theme.COLORS.GRAY_4};
    border-radius: 6px;
    padding: 10px 52px 10px 10px;
    font-size: ${({ theme }) => theme.FONT.SIZE.S14};
    font-family: ${({ theme }) => theme.FONT.STYLE.KANIT_LIGHT};
    color: ${({ theme }) => theme.COLORS.GRAY_5};
  }
  .arrow_dropdown {
    top: 18px;
  }
  .errors {
    margin-top: 2px;
    font-size: ${({ theme }) => theme.FONT.SIZE.S12};
    color: ${({ theme }) => theme.COLORS.RED_1};
  }
  .theme_standard {
  }
`;
