import React from 'react';
import { SplashContainerStyled } from './styled';
import { connect } from 'react-redux';
import { userService } from 'apiServices';
import { withTranslation } from 'react-i18next';
import { setReduxAuthen } from 'store/actions';
import queryString from 'query-string';
import { withRouter } from 'navigations/withRouter';
import { ROUTE_PATH } from 'utils/constants/routePath';
import { Displays } from 'components';

const liff = window.liff;

class SplashContainer extends React.Component {
  state = {};

  componentDidMount() {
    this.initialLineLiff();
  }

  initialLineLiff = async () => {
    let { page } = queryString.parse(this.props.router.location.search);
    await liff.init({ liffId: `1657310383-jD5Benqp` }).catch((err) => {
      throw err;
    });
    if (liff.isLoggedIn()) {
      const accessToken = liff.getAccessToken();
      const getProfile = await liff.getProfile();
      let params = {
        access_token: accessToken,
        user_id: getProfile.userId,
      };
      let res = await userService.POST_LINE_LOGIN(params);
      if (res && res.status === 200) {
        localStorage.setItem('token', res.token);
        let resProfile = await userService.GET_MY_PROFILE();
        if (resProfile && resProfile.status === 200) {
          this.props.setReduxAuthen(resProfile.data);
          if (!resProfile.data.phone_number || !resProfile.data.province) {
            this.props.router.navigate(ROUTE_PATH.REGISTER);
          } else {
            if (!page) {
              this.props.router.navigate(ROUTE_PATH.PRODUCT_SCAN_QR_CODE);
            } else {
              this.props.router.navigate(ROUTE_PATH[page]);
            }
          }
        }
      }
    } else {
      liff.login();
    }
  };

  render() {
    return (
      <SplashContainerStyled useSuspense={false}>
        {/* <img alt="smp logo" src="/assets/images/logos/logo.png" /> */}
        <Displays.Loading />
      </SplashContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  authenRedux: state.authenRedux,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxAuthen: (data) => dispatch(setReduxAuthen(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(withRouter(SplashContainer)));
